/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  systemListViewAction,
  updateSystemList,
  getAuditLogDetailsAction,
  getAuditLogRowDetailsAction
} from '../../../Store/Actions/systemList/systemListActions';
import moment from 'moment';
import * as SystemListConstants from '../systemListConstants';
import {
  generateUUID,
  validateDateMinimumValue,
  compareTwoDatesGreaterThanOrEqual,
  getUTCTimeStamp
} from '../../../../../SharedModules/DateUtilities/DateUtilities';
import NoSaveMessage from '../../../../../SharedModules/Errors/NoSaveMessage';
import {
  AppConfigDropdownActions,
  DataElementDropdownActions
} from '../../../Store/Actions/AppConfigCommon/AppConfigActions';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import SystemListAddTable from './SystemListAddTable';
import MapsetIdTable from './MapsetIdTable';
import * as SystemListSearchConstants from './SystemListSearchConstants';
import { Prompt } from 'react-router-dom';

import dropdownCriteria from './SystemListAddUpdate.json';
import TabPanel from '../../../../../SharedModules/TabPanel/TabPanel';
import {
  DialogActions,
  DialogContent
} from '../../../../../SharedModules/Dialog/DialogUtilities';
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../../SharedModules/Errors/SuccessMessage';
import * as AppConstants from '../../../../../SharedModules/AppConstants';
import Notes from '../../../../../SharedModules/Notes/Notes';
import Footer from '../../../../../SharedModules/Layout/footer';
import NavHOC from '../../../../../SharedModules/Navigation/NavHOC';
import Radio from '@material-ui/core/Radio';
import AuditTableComponent from '../../../../../SharedModules/AuditLog/AuditTableComponent';
import { GET_APP_DROPDOWNS } from "../../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown';

export default NavHOC({ Wrapped: SearchListAdd, url: '/SystemListAddUpdate' });

function SearchListAdd (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [initalFocusCheck, setInitalFocusCheck] = React.useState(false);
  const dispatch = useDispatch();
  const dropDownDispatch = dropdownvalues =>
    dispatch(AppConfigDropdownActions(dropdownvalues));
  const dataElemDropDownDispatch = () => dispatch(DataElementDropdownActions());
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  useEffect(() => {
    dropDownDispatch(dropdownCriteria);
    dataElemDropDownDispatch();
    onDropdowns([
      Dropdowns.R_FUNC_AREA_CD,
      Dropdowns.LOB_CODE
    ]);
  }, []);
  const [searchCriteriarowClick, setSearchCriteriarowClick] = React.useState(
    props.history.location.state.payloadData
  );
  const onRowClick = () =>
    dispatch(systemListViewAction(searchCriteriarowClick));
  const onUpdateSystemList = value => dispatch(updateSystemList(value));
  const [systemListAddTableData, setSystemListAddTableData] = React.useState(
    []
  );
  const [add, setAdd] = React.useState(true);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const logInUserID = useSelector(state => state.sharedState.logInUserID);

  let dropdown = [];
  useEffect(() => {
    onRowClick();
  }, [props.history.location.state]);
  let errorMessagesArray = [];
  const successMessagesArray = [];
  const [redirectToMapset, setRedirectToMapset] = React.useState(false);
  const [openMapsetDialog, setOpenMapsetDialog] = React.useState(false);
  const redirectPageToMapsetAdd = () => {
    setRedirectToMapset(true);
  };
  const openMapsetPopUpDialog = () => {
    setOpenMapsetDialog(true);
  };
  const closeMapsetPopUpDialog = () => {
    setOpenMapsetDialog(false);
  };
  const [functionalAreaData, setFunctionalAreaData] = React.useState([]);
  const [listTypeData, setListTypeData] = React.useState([]);
  const [dataElementNameData, setDataElementNameData] = React.useState([]);
  const [lobCodeData, setLobCodeData] = React.useState([]);

  const [voidedData, setVoidedData] = React.useState([]);
  const [nonVoidedData, setNonVoidedData] = React.useState([]);
  const [retainEdit, setRetainEdit] = React.useState({});

  const [tempNonVolatile, setTempNonVolatile] = React.useState([]);
  const [tempConcat, setTempConcat] = React.useState([]);
  const [isFilterEnable, setIsFilterEnable] = React.useState(false);
  const [rowSystemListDetailData, setRowSystemListDetailData] = React.useState(
    []
  );
  const [tableData, setTableData] = React.useState([]);
  const [MapIdList, setMapIdList] = React.useState([]);
  const payloadData = useSelector(
    state => state.appConfigState.systemListState.systemListViewData
  );

  let voidDateArray = [];
  let nonVoidDateArray = [];

  const userInquiryPrivileges = !global.globalIsReadOnly();
  // Audit Log Implementation starts
  const [showLogTable, setShowLogTable] = React.useState(false);

  const onClickAuditLog = () => {
    const tableName = 'r_list_hdr_tb';
    const keyValue = {
      r_func_area_cd: values.functionalArea,
      r_list_num: values.listNumber
    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, 'auditLogData'));
  };
  useEffect(() => {
    if (showLogTable && document.getElementById('audit-table')) { document.getElementById('audit-table').scrollIntoView(); }
  }, [showLogTable]);
  const auditLogData = useSelector(state => (state.appConfigState.systemListState.auditData));
  const [auditRowData, setAuditRowData] = React.useState([]);
  const filterAuditTable = (rowData) => {
    return (rowData.afterValue || rowData.beforeValue);
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);

  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const auditLogTableData = useSelector(state => (state.appConfigState.systemListState.auditRowData));
  const [auditTableData, setAuditTableData] = React.useState([]);
  const [close, setClose] = React.useState(true);

  const onClickAuditDialogLog = (row) => {
    const tableName = 'r_list_dtl_tb';
    const keyValue = {
      r_func_area_cd: values.functionalArea,
      r_list_num: values.listNumber,
      r_list_dtl_sk: row.listDetail
    };
    dispatch(getAuditLogRowDetailsAction(tableName, keyValue, 'auditLogTableData'));
    setShowLogDialogTable(true);
  };
  useEffect(() => {
    if (auditLogTableData) {
      setAuditTableData(auditLogTableData.filter(filterAuditTable));
    }
  }, [auditLogTableData]);

  useEffect(() => {
    if (showLogDialogTable && document.getElementById('audit-dialog-table')) { document.getElementById('audit-dialog-table').scrollIntoView(); }
  }, [showLogDialogTable]);
  // Audit Log Implementation ends

  useEffect(() => {
    if (payloadData !== undefined) {
      setSystemListAddTableData([]);
      setValues({
        functionalArea: payloadData ? payloadData[0]?.functionalArea : null,
        listNumber: payloadData ? payloadData[0]?.listNumber : null,
        businessName: payloadData ? payloadData[0]?.listBusinessName : null,
        dataElementName: payloadData ? payloadData[0]?.dataElementName : null,
        listType: payloadData ? payloadData[0]?.listType : null,
        usedInMapSet: 'No Data',
        description: payloadData ? payloadData[0]?.description : null
      });
      setMapIdList(payloadData ? payloadData[0]?.mapSetIds : []);
      if (props.history.location.state.payloadData.SuccessMessages) {
        setFocusCheck(false);
        setSuccessMessages(
          props.history.location.state.payloadData.SuccessMessages
        );
      }
      if (payloadData[0].noteSetVO) {
        const noteSetVO = payloadData[0].noteSetVO;
        const notesTable = payloadData[0].noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
      if (
        payloadData &&
        payloadData.length > 0 &&
        payloadData[0].systemListDetails
      ) {
        nonVoidDateArray = [];
        voidDateArray = [];

        payloadData[0].systemListDetails.map((option) => {
          if (option.voidDate) {
            voidDateArray.push(option);
            setVoidedData(voidDateArray);
          } else {
            nonVoidDateArray.push(option);
            setNonVoidedData(nonVoidDateArray);
          }
        });
      }
      setSystemListAddTableData(payloadData ? nonVoidDateArray : []);
      setTableData(payloadData ? payloadData[0].systemListDetails : []);
    }
  }, [payloadData]);

  useEffect(() => {
    const nonVoidDateArray = [];
    const voidDateArray = [];
    tableData.map((option) => {
      if (option.voidDate) {
        voidDateArray.push(option);
        setVoidedData(voidDateArray);
      } else {
        nonVoidDateArray.push(option);
        setNonVoidedData(nonVoidDateArray);
      }
    });
  }, [tableData]);

  dropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.appConfigDropdown
  );
  const datElemDropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.dataElementNameDropdown
  );

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      const tempData = JSON.parse(JSON.stringify(values));
      if (dropdown.listObj['Reference#1017']) {
        setFunctionalAreaData(dropdown.listObj['Reference#1017']);
        if (dropdown.listObj['Reference#1017'].length === 1) {
          tempData.functionalArea = dropdown.listObj['Reference#1017'][0].code;
        }
      }
      if (dropdown.listObj['Reference#2']) {
        setListTypeData(dropdown.listObj['Reference#2']);
        if (dropdown.listObj['Reference#2'].length === 1) {
          tempData.listType = dropdown.listObj['Reference#2'][0].code;
        }
      }
      if (dropdown.listObj['Reference#1019']) {
        setLobCodeData(dropdown.listObj['Reference#1019']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
      setValues(tempData);
    }
  }, [dropdown]);

  useEffect(() => {
    if (datElemDropdown && datElemDropdown.listDataElements) {
      setDataElementNameData(datElemDropdown.listDataElements);
      if (datElemDropdown.listDataElements.length === 1) {
        setValues({ ...values, dataElementName: datElemDropdown.listDataElements[0].code });
      }
    }
  }, [datElemDropdown]);

  const handleChanges = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setFocusCheck(true);
    setValues({ ...values, [name]: event.target.value });
    if (name === 'dataElementName') {
      setDataElementNameValue(event.target.value);
    }
  };
  const [openCrossReference, setOpenCrossReference] = React.useState(false);

  const [
    {
      showFunctionalAreaError,
      showListNumberError,
      showDataElementNameError,
      showListTypeError,
      showDescriptionError,
      showBusinessNameError,
      showBeginValueError,
      showEndValueError,
      showBeginDateError,
      showEndDateError,
      showRevenueCodeBeginValueError,
      showRevenueCodeEndValueError,
      showDiagNumericEndValueError,
      showDiagNumericBeginValueError
    },
    setShowError
  ] = React.useState(false);
  const [dataElementNameValue, setDataElementNameValue] = React.useState('');

  const [
    {
      showDescriptionErrorText,
      showListtypeErrorText,
      showListNumberErrorText,
      showFunctionalAreaErrorText,
      showDataElementNameErrorText,
      showBusinessNameErrorText,
      showBeginValueErrorText,
      showEndValueErrorText,
      showBeginDateErrorText,
      showEndDateErrorText,
      showDiagNumericBeginValueErrorText,
      showDiagNumericEndValueErrorText
    },
    setShowErrorText
  ] = React.useState('');

  const handleCloseCrossReference = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    setOpenCrossReference(false);
    setShowErrorText('');
    setErrorMessages([]);
  };

  const [values, setValues] = React.useState({
    functionalArea: DEFAULT_DD_VALUE,
    listNumber: '',
    businessName: '',
    dataElementName: DEFAULT_DD_VALUE,
    listType: DEFAULT_DD_VALUE,
    usedInMapSet: 'No Data',
    description: ''
  });
  const [
    showSystemlistValueTable,
    setShowSystemlistValueTable
  ] = React.useState(false);
  const [showAdditionalDetails1, setShowAdditionalDetails1] = React.useState(
    false
  );
  const [value, setValue] = React.useState(0);

  const [successMessages, setSuccessMessages] = React.useState([]);

  const [
    concatbothvoidandnonvoid,
    setConcatbothVoidAndNonVoid
  ] = React.useState([]);

  // notes

  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});

  const addSystemListDetails = () => {
    setCurrentRow(undefined);

    let showFunctionalAreaError;
    let showListTypeError;
    let showDataElementNameError;
    let showListNumberError;
    let showBusinessNameError;
    let showDescriptionError = false;
    let showFunctionalAreaErrorText;
    let showListtypeErrorText;
    let showDataElementNameErrorText;
    let showListNumberErrorText;
    let showBusinessNameErrorText;
    let showDescriptionErrorText = '';

    if (values.listType === DEFAULT_DD_VALUE || listTypeDataVal === undefined) {
      showListTypeError = true;
      setShowError({ showListTypeError: showListTypeError });
      errorMessagesArray.push(SystemListSearchConstants.LIST_TYPE_REQUIRED);
      showListtypeErrorText = SystemListSearchConstants.LIST_TYPE_REQUIRED;
      setErrorMessages(errorMessagesArray);
    }
    if (values.dataElementName === DEFAULT_DD_VALUE) {
      showDataElementNameError = true;
      setShowError({ showDataElementNameError: showDataElementNameError });
      errorMessagesArray.push(
        SystemListSearchConstants.DATAELEMENT_NAME_REQUIRED
      );
      showDataElementNameErrorText =
        SystemListSearchConstants.DATAELEMENT_NAME_REQUIRED;
      setErrorMessages(errorMessagesArray);
    }
    if (values.listNumber === '') {
      showListNumberError = true;
      setShowError({ showListNumberError: showListNumberError });
      errorMessagesArray.push(SystemListSearchConstants.LISTNUMBER_REQUIRED);
      showListNumberErrorText = SystemListSearchConstants.LISTNUMBER_REQUIRED;
      setErrorMessages(errorMessagesArray);
    }
    if (values.businessName === '') {
      showBusinessNameError = true;
      showBusinessNameErrorText =
        SystemListSearchConstants.BUSINESS_NAME_REQUIRED_ERROR;
      errorMessagesArray.push(
        SystemListSearchConstants.BUSINESS_NAME_REQUIRED_ERROR
      );
      setErrorMessages(errorMessagesArray);
    }
    if (values.description === '') {
      showDescriptionError = true;
      showDescriptionErrorText = SystemListSearchConstants.DESCRIPTION_REQUIRED;
      errorMessagesArray.push(SystemListSearchConstants.DESCRIPTION_REQUIRED);
      setErrorMessages(errorMessagesArray);
    }

    setShowError({
      showFunctionalAreaError: showFunctionalAreaError,
      showListTypeError: showListTypeError,
      showDataElementNameError: showDataElementNameError,
      showListNumberError: showListNumberError,
      showBusinessNameError: showBusinessNameError,
      showDescriptionError: showDescriptionError
    });
    setShowErrorText({
      showFunctionalAreaErrorText: showFunctionalAreaErrorText,
      showListtypeErrorText: showListtypeErrorText,
      showDataElementNameErrorText: showDataElementNameErrorText,
      showListNumberErrorText: showListNumberErrorText,
      showBusinessNameErrorText: showBusinessNameErrorText,
      showDescriptionErrorText: showDescriptionErrorText
    });

    if (errorMessagesArray.length === 0) {
      setErrorMessages([]);
      setOpenCrossReference(true);
      setAdd(true);
      setSystemListValues({
        lob: DEFAULT_DD_VALUE,
        beginValue: '',
        endValue: '',
        sortNum: ''
      });
      setSelectedEndDate(new Date('9999-12-31T13:00:00.000+0000'));
      setSelectedBeginDate(null);
    }
  };

  const [errorMessages, setErrorMessages] = React.useState([]);
  const checkFormValidations = () => {
    var showDescriptionError;
    var showListNumberError;
    var showListTypeError;
    var showBusinessNameError;
    var showFunctionalAreaError;
    var showDataElementNameError;
    var showBusinessNameErrorText;
    var showDescriptionErrorText;
    var showListNumberErrorText;
    var showListtypeErrorText;
    var showFunctionalAreaErrorText;
    var showDataElementNameErrorText;
    setErrorMessages([]);
    if (values.businessName === '') {
      showBusinessNameError = true;
      showBusinessNameErrorText =
        SystemListSearchConstants.BUSINESS_NAME_REQUIRED_ERROR;
      errorMessagesArray.push(
        SystemListSearchConstants.BUSINESS_NAME_REQUIRED_ERROR
      );
      setErrorMessages(errorMessagesArray);
    }

    if (values.description === '') {
      showDescriptionError = true;
      showDescriptionErrorText = SystemListSearchConstants.DESCRIPTION_REQUIRED;
      errorMessagesArray.push(SystemListSearchConstants.DESCRIPTION_REQUIRED);
      setErrorMessages(errorMessagesArray);
    }

    if (values.listType === DEFAULT_DD_VALUE) {
      showListTypeError = true;
      setShowError({ showListTypeError: showListTypeError });
      errorMessagesArray.push(SystemListSearchConstants.LIST_TYPE_REQUIRED);
      showListtypeErrorText = SystemListSearchConstants.LIST_TYPE_REQUIRED;
      setErrorMessages(errorMessagesArray);
    }
    if (values.dataElementName === DEFAULT_DD_VALUE) {
      showDataElementNameError = true;
      setShowError({ showDataElementNameError: showDataElementNameError });
      errorMessagesArray.push(
        SystemListSearchConstants.DATAELEMENT_NAME_REQUIRED
      );
      showDataElementNameErrorText =
        SystemListSearchConstants.DATAELEMENT_NAME_REQUIRED;
      setErrorMessages(errorMessagesArray);
    }
    if (values.functionalArea === DEFAULT_DD_VALUE) {
      showFunctionalAreaError = true;
      setShowError({ showFunctionalAreaError: showFunctionalAreaError });
      errorMessagesArray.push(
        SystemListSearchConstants.FUNCTIONALAREA_REQUIRED
      );
      showFunctionalAreaErrorText =
        SystemListSearchConstants.FUNCTIONALAREA_REQUIRED;
      setErrorMessages(errorMessagesArray);
    }
    if (values.listNumber === '') {
      showListNumberError = true;
      setShowError({ showListNumberError: showListNumberError });
      errorMessagesArray.push(SystemListSearchConstants.LISTNUMBER_REQUIRED);
      showListNumberErrorText = SystemListSearchConstants.LISTNUMBER_REQUIRED;
      setErrorMessages(errorMessagesArray);
    }

    setShowError({
      showDescriptionError: showDescriptionError,
      showListTypeError: showListTypeError,
      showListNumberError: showListNumberError,
      showBusinessNameError: showBusinessNameError,
      showFunctionalAreaError: showFunctionalAreaError,
      showDataElementNameError: showDataElementNameError
    });

    setShowErrorText({
      showDescriptionErrorText: showDescriptionErrorText,
      showListNumberErrorText: showListNumberErrorText,
      showListtypeErrorText: showListtypeErrorText,
      showDataElementNameErrorText: showDataElementNameErrorText,
      showFunctionalAreaErrorText: showFunctionalAreaErrorText,
      showBusinessNameErrorText: showBusinessNameErrorText
    });

    if (
      showBusinessNameError ||
      showDescriptionError ||
      showListNumberError ||
      showDataElementNameError ||
      showListTypeError ||
      showDescriptionError ||
      showBusinessNameError
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkPopupFormValidations = () => {
    var showLobCodeError;
    var showBeginDateError;
    var showEndDateError;
    var showBeginValueError;
    var showEndValueError;

    var showDiagNumericBeginValueError;
    var showRevenueCodeBeginValueError;
    var showRevenueCodeEndValueError;
    var showDiagNumericEndValueError = false;
    var showLobCodeErrorText;
    var showBeginValueErrorText;
    var showEndValueErrorText;
    var showBeginDateErrorText;
    var showEndDateErrorText;
    var showDiagNumericBeginValueErrorText;
    var showDiagNumericEndValueErrorText = '';
    const tempErrormesArray = [];
    if (systemListValues.beginValue === '') {
      showBeginValueError = true;
      setShowError({ showBeginValueError: showBeginValueError });
      showBeginValueErrorText = SystemListSearchConstants.BEGINVALUE_REQUIRED;
      tempErrormesArray.push(showBeginValueErrorText);
    } else {
      if (dataElementNameValue === 'R_REV_CD') {
        var numbers = /^[0-9]+$/;
        if (systemListValues.beginValue.length === 4) {
          showRevenueCodeBeginValueError = false;
          if (systemListValues.beginValue.match(numbers)) {
            showRevenueCodeBeginValueError = false;
            setShowError({
              showRevenueCodeBeginValueError: showRevenueCodeBeginValueError
            });
          } else {
            showRevenueCodeBeginValueError = true;
            setShowError({
              showRevenueCodeBeginValueError: showRevenueCodeBeginValueError
            });
            showBeginValueErrorText =
              SystemListSearchConstants.REVENUE_CODE_TEXT_BEGINVALUE;
            tempErrormesArray.push(showBeginValueErrorText);
          }
        } else {
          showRevenueCodeBeginValueError = true;
          setShowError({
            showRevenueCodeBeginValueError: showRevenueCodeBeginValueError
          });
          showBeginValueErrorText =
            SystemListSearchConstants.REVENUE_CODE_TEXT_BEGINVALUE;
          tempErrormesArray.push(showBeginValueErrorText);
        }
      }
      if (dataElementNameValue === 'R_DIAG_CD') {
        var test = SystemListConstants.ADDVALUE_REGEX;
        if (systemListValues.beginValue.match(test)) {
          showDiagNumericBeginValueError = false;
          setShowError({
            showDiagNumericBeginValueError: showDiagNumericBeginValueError
          });
          showDiagNumericBeginValueErrorText =
            SystemListSearchConstants.DIAG_BEGINVALUE_ERROR;
          tempErrormesArray.push(showDiagNumericBeginValueErrorText);
        } else {
          showDiagNumericBeginValueError = true;
          setShowError({
            showDiagNumericBeginValueError: showDiagNumericBeginValueError
          });
          showDiagNumericBeginValueErrorText =
            SystemListSearchConstants.DIAG_BEGINVALUE_ERROR;
          tempErrormesArray.push(showDiagNumericBeginValueErrorText);
        }
      }
      if (dataElementNameValue === 'R_SURG_PROC_CD') {
        const test = SystemListConstants.SURGICAL_REGEX;
        if (systemListValues.beginValue.match(test)) {
          showDiagNumericBeginValueError = false;
          setShowError({
            showDiagNumericBeginValueError: showDiagNumericBeginValueError
          });
          showDiagNumericBeginValueErrorText =
            SystemListSearchConstants.DIAG_BEGINVALUE_INVALID_ERROR;
          tempErrormesArray.push(showDiagNumericBeginValueErrorText);
        } else {
          showDiagNumericBeginValueError = true;
          setShowError({
            showDiagNumericBeginValueError: showDiagNumericBeginValueError
          });
          showDiagNumericBeginValueErrorText =
            SystemListSearchConstants.DIAG_BEGINVALUE_INVALID_ERROR;
          tempErrormesArray.push(showDiagNumericBeginValueErrorText);
        }
      }
      showBeginValueError = false;
    }

    if (systemListValues.endValue === '') {
      if (values.listType === 'UIS') {
        showEndValueError = false;
        setShowError({ showEndValueError: showEndValueError });
      } else {
        showEndValueError = true;
        setShowError({ showEndValueError: showEndValueError });
        showEndValueErrorText = SystemListSearchConstants.ENDVALUE_REQUIRED;
        tempErrormesArray.push(showEndValueErrorText);
      }
    } else {
      if (dataElementNameValue === 'R_REV_CD') {
        const numbers = /^[0-9]+$/;
        if (systemListValues.endValue.length === 4) {
          showRevenueCodeEndValueError = false;
          if (systemListValues.endValue.match(numbers)) {
            showRevenueCodeBeginValueError = false;
            setShowError({
              showRevenueCodeEndValueError: showRevenueCodeEndValueError
            });
          } else {
            showRevenueCodeEndValueError = true;
            setShowError({
              showRevenueCodeEndValueError: showRevenueCodeEndValueError
            });
            showBeginValueErrorText =
              SystemListSearchConstants.REVENUE_CODE_TEXT_ENDVALUE;
            tempErrormesArray.push(showBeginValueErrorText);
          }
        } else {
          showRevenueCodeEndValueError = true;
          setShowError({
            showRevenueCodeEndValueError: showRevenueCodeEndValueError
          });
          showBeginValueErrorText =
            SystemListSearchConstants.REVENUE_CODE_TEXT_ENDVALUE;
          tempErrormesArray.push(showBeginValueErrorText);
        }
      }
      if (dataElementNameValue === 'R_DIAG_CD') {
        const test = SystemListConstants.ADDVALUE_REGEX;
        if (systemListValues.endValue.match(test)) {
          showDiagNumericEndValueError = false;
          setShowError({
            showDiagNumericEndValueError: showDiagNumericEndValueError
          });
          showDiagNumericEndValueErrorText =
            SystemListSearchConstants.DIAG_BEGINVALUE_ERROR;
          tempErrormesArray.push(showDiagNumericEndValueErrorText);
        } else {
          showDiagNumericEndValueError = true;
          setShowError({
            showDiagNumericEndValueError: showDiagNumericEndValueError
          });
          showDiagNumericEndValueErrorText =
            SystemListSearchConstants.DIAG_BEGINVALUE_ERROR;
          tempErrormesArray.push(showDiagNumericEndValueErrorText);
        }
      }
      if (dataElementNameValue === 'R_SURG_PROC_CD') {
        const test = SystemListConstants.SURGICAL_REGEX;
        if (systemListValues.endValue.match(test)) {
          showDiagNumericEndValueError = false;
          setShowError({
            showDiagNumericEndValueError: showDiagNumericEndValueError
          });
          showDiagNumericEndValueErrorText =
            SystemListSearchConstants.DIAG_BEGINVALUE_ERROR;
          tempErrormesArray.push(showDiagNumericEndValueErrorText);
        } else {
          showDiagNumericEndValueError = true;
          setShowError({
            showDiagNumericEndValueError: showDiagNumericEndValueError
          });
          showDiagNumericEndValueErrorText =
            SystemListSearchConstants.DIAG_BEGINVALUE_ERROR;
          tempErrormesArray.push(showDiagNumericEndValueErrorText);
        }
      }
      showEndValueError = false;
    }

    // Begin Value validation
    if (
      systemListValues.beginValue !== '' &&
      systemListValues.endValue !== '' &&
      !isNaN(systemListValues.beginValue) &&
      !isNaN(systemListValues.endValue) &&
      Number(systemListValues.beginValue) > Number(systemListValues.endValue) &&
      !showRevenueCodeBeginValueError &&
      !showDiagNumericBeginValueError &&
      !showBeginValueError &&
      values.listType !== 'UIS'
    ) {
      showBeginValueError = true;
      setShowError({ showBeginValueError: showBeginValueError });
      showBeginValueErrorText =
        SystemListSearchConstants.BEGINVALUE_INVALID_TEXT;
      tempErrormesArray.push(showBeginValueErrorText);
    }

    // date validations
    if (selectedBeginDate) {
      if (selectedBeginDate.toString() !== 'Invalid Date') {
        if (validateDateMinimumValue(selectedBeginDate)) {
          showBeginDateError = true;
          setShowError({ showBeginDateError: showBeginDateError });
          showBeginDateErrorText = AppConstants.DATE_ERROR_1964;
          tempErrormesArray.push(showBeginDateErrorText);
        }
      } else {
        showBeginDateError = true;
        setShowError({ showBeginDateError: showBeginDateError });
        showBeginDateErrorText = SystemListSearchConstants.BEGIN_DATE_INVALID;
        tempErrormesArray.push(showBeginDateErrorText);
      }
    } else {
      showBeginDateError = true;
      setShowError({ showBeginDateError: showBeginDateError });
      showBeginDateErrorText = SystemListSearchConstants.BEGIN_DATE_REQUIRED;
      tempErrormesArray.push(showBeginDateErrorText);
    }
    if (selectedEndDate) {
      if (selectedEndDate.toString() !== 'Invalid Date') {
        if (validateDateMinimumValue(selectedEndDate)) {
          showEndDateError = true;
          setShowError({ showEndDateError: showEndDateError });
          showEndDateErrorText = AppConstants.DATE_ERROR_1964;
          tempErrormesArray.push(showEndDateErrorText);
        }
      } else {
        showEndDateError = true;
        setShowError({ showEndDateError: showEndDateError });
        showEndDateErrorText = SystemListSearchConstants.END_DATE_INVALID;
        tempErrormesArray.push(showEndDateErrorText);
      }
    } else {
      showEndDateError = true;
      setShowError({ showEndDateError: showEndDateError });
      showEndDateErrorText = SystemListSearchConstants.END_DATE_REQUIRED;
      tempErrormesArray.push(showEndDateErrorText);
    }
    if (
      selectedBeginDate &&
      selectedEndDate &&
      !showBeginDateError &&
      !showEndDateError &&
      compareTwoDatesGreaterThanOrEqual(selectedEndDate, selectedBeginDate)
    ) {
      showBeginDateError = true;
      setShowError({ showBeginDateError: showBeginDateError });
      showBeginDateErrorText = SystemListSearchConstants.BEGIN_DATE_LESS;
      tempErrormesArray.push(showBeginDateErrorText);
    }
    setErrorMessages(tempErrormesArray);

    setShowError({
      showLobCodeError: showLobCodeError,
      showBeginValueError: showBeginValueError,
      showEndValueError: showEndValueError,
      showBeginDateError: showBeginDateError,
      showEndDateError: showEndDateError,
      showRevenueCodeBeginValueError: showRevenueCodeBeginValueError,
      showDiagNumericBeginValueError: showDiagNumericBeginValueError,
      showRevenueCodeEndValueError: showRevenueCodeEndValueError,
      showDiagNumericEndValueError: showDiagNumericEndValueError
    });

    setShowErrorText({
      showLobCodeErrorText: showLobCodeErrorText,
      showBeginValueErrorText: showBeginValueErrorText,
      showEndValueErrorText: showEndValueErrorText,
      showBeginDateErrorText: showBeginDateErrorText,
      showEndDateErrorText: showEndDateErrorText,
      showDiagNumericBeginValueErrorText: showDiagNumericBeginValueErrorText,
      showDiagNumericEndValueErrorText: showDiagNumericEndValueErrorText
    });

    if (
      showLobCodeError &&
      showBeginValueError &&
      showEndValueError &&
      showBeginDateError &&
      showEndDateError
    ) {
      return false;
    } else {
      if (showLobCodeError) {
        return false;
      }
      if (showBeginValueError) {
        return false;
      }
      if (showEndValueError) {
        return false;
      }
      if (showBeginDateError) {
        return false;
      }
      if (showEndDateError) {
        return false;
      }
      if (showRevenueCodeBeginValueError && showRevenueCodeEndValueError) {
        return false;
      }
      if (showRevenueCodeBeginValueError) {
        return false;
      }
      if (showRevenueCodeEndValueError) {
        return false;
      }
      if (showDiagNumericBeginValueError) {
        return false;
      }
      if (showDiagNumericEndValueError) {
        return false;
      }
      return true;
    }
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = name => event => {
    setFocusCheck(true);
    setFocusCheck(true);
    setAllowNavigation(false);
    setValues({ [name]: event.target.value });
  };

  const [currentRow, setCurrentRow] = React.useState();
  const [systemListValues, setSystemListValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    beginValue: '',
    endValue: '',
    sortNum: '',
    voidSelected: 'No'
  });
  const [systemListFilterValues, setSystemListFilterValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    beginValue: '',
    endValue: ''
  });

  const [editData, setEditData] = React.useState({});
  const [showVoid, setShowVoid] = React.useState(false);

  const resetSystemListValues = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    setErrorMessages([]);
    if (add) {
      setSystemListValues({
        lob: DEFAULT_DD_VALUE,
        beginValue: '',
        endValue: '',
        sortNum: ''
      });
      setSelectedBeginDate(null);
      setSelectedEndDate(new Date('9999-12-31T13:00:00.000+0000'));
    } else {
      setSystemListValues({
        lob: retainEdit.lineofbusiness,
        beginValue: retainEdit.startingValue
          ? retainEdit.startingValue
          : retainEdit.beginValue
            ? retainEdit.beginValue
            : '',
        endValue: retainEdit.endingValue
          ? retainEdit.endingValue
          : retainEdit.endValue
            ? retainEdit.endValue
            : '',
        voidSelected: 'No',
        sortNum: retainEdit.sortNum
      });
      setSelectedBeginDate(retainEdit.beginDate);
      setSelectedEndDate(retainEdit.endDate);
    }
    setShowError({
      showLobCodeError: false,
      showEndValueError: false,
      showBeginDateError: false,
      showEndDateError: false,
      showOverLappingError: false,
      showBeginValueError: false,
      showRevenueCodeBeginValueError: false,
      showRevenueCodeEndValueError: false,
      showBeginValueInvalidError: false,
      showDiagNumericBeginValueError: false,
      showDiagNumericEndValueError: false
    });
  };

  const updateResponse = useSelector(
    state => state.appConfigState.systemListState.systemListUpdateResponse
  );

  useEffect(() => {
    if (updateResponse) {

    }
    if (
      updateResponse && !updateResponse.systemFailure &&
      updateResponse.flag &&
      updateResponse.flag === 'success'
    ) {
      setShowVoid(false);
      successMessagesArray.push(SystemListSearchConstants.UPDATED_SUCCESSFULLY);
      // call fect api here to reload
      setAllowNavigation(false);
      setFocusCheck(false);
      if (successMessages.length === 0) {
        setSuccessMessages(successMessagesArray);
      }
      const payloadData = {
        listNumber: values.listNumber,
        functionalArea: values.functionalArea,
        dataElementName: values.dataElementName,
        description: values.description,
        listType: values.listType,
        listBusinessName: values.listBusinessName,
        funcAreaDesc: values.funcAreaDesc,
        SuccessMessages: successMessagesArray
      };
      dispatch(systemListViewAction(payloadData));
      setSystemListAddTableData(systemListAddTableData);
    } else if (
      updateResponse && !updateResponse.systemFailure &&
      updateResponse.flag !== undefined &&
      updateResponse.flag !== 'success'
    ) {
      setErrorMessages(updateResponse.flag);
    }
    if (updateResponse && updateResponse.systemFailure) {
      errorMessagesArray.push(SystemListSearchConstants.ERR_PROCESSING_REQ);
      if (errorMessages.length === 0) {
        setErrorMessages(errorMessagesArray);
      }
    }
  }, [updateResponse]);

  const addSystemListValuesToTable = () => {
    setSuccessMessages([]);
    setErrorMessages([]);
    if (add) {
      if (checkPopupFormValidations()) {
        let count = 0;
        if (nonVoidedData.length > 0) {
          nonVoidedData.map((value) => {
            if (value.startingValue === systemListValues.beginValue) {
              const valueBeginDate = moment(value.beginDate).format('MM/DD/YYYY');
              const selectBeginDate = moment(selectedBeginDate).format('MM/DD/YYYY');
              const valueEndDate = moment(value.endDate).format('MM/DD/YYYY');
              const selectEndDate = moment(selectedEndDate).format('MM/DD/YYYY');
              if (
                valueBeginDate === selectBeginDate ||
                valueEndDate === selectEndDate ||
                valueEndDate === selectBeginDate ||
                valueBeginDate === selectEndDate
              ) {
                count = count + 1;
              } else if (
                moment(valueBeginDate, 'MM/DD/YYYY') <
                moment(selectBeginDate, 'MM/DD/YYYY') &&
                moment(valueEndDate, 'MM/DD/YYYY') >
                moment(selectEndDate, 'MM/DD/YYYY')
              ) {
                count = count + 1;
              } else if (
                moment(valueBeginDate, 'MM/DD/YYYY') >
                moment(selectBeginDate, 'MM/DD/YYYY') &&
                moment(selectEndDate, 'MM/DD/YYYY') >
                moment(valueBeginDate, 'MM/DD/YYYY')
              ) {
                count = count + 1;
              } else if (moment(selectBeginDate, 'MM/DD/YYYY') <
              moment(valueEndDate, 'MM/DD/YYYY') &&
              moment(selectEndDate, 'MM/DD/YYYY') >
              moment(valueEndDate, 'MM/DD/YYYY')) {
                count = count + 1;
              }
            }
          });
        }
        let showDateOverlappingError = false;
        if (count > 0) {
          errorMessagesArray = [];
          setErrorMessages([]);
          showDateOverlappingError = true;
          errorMessagesArray.push(SystemListSearchConstants.OVERLAPPING_ERROR);
          setErrorMessages(errorMessagesArray);
          setShowError({ showOverLappingError: showDateOverlappingError });
          window.scrollTo(0, 0);
        }
        if (!showDateOverlappingError) {
          const value = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            deletedSystemListRanges: [],
            lineofbusiness: systemListValues.lob,
            voidIndicator: '',
            disableVoidIndCode: false,
            beginDate: moment(selectedBeginDate).format('MM/DD/YYYY'),
            endDate: moment(selectedEndDate).format('MM/DD/YYYY'),
            ranges: null,
            voidDate: null,
            tempVoidDate: null,
            showVoidRecord: true,
            listDetail: null,
            endingValue:
              values.listType === 'UIS'
                ? systemListValues.beginValue
                : systemListValues.endValue,
            startingValue: systemListValues.beginValue,
            sortNum: systemListValues.sortNum,
            filterEndingValue: null,
            filterStartingValue: null,
            filterBeginDate: null,
            filterEndDate: null,
            filterLineofbusiness: null,
            lineofbusiness1: null,
            isNewRow: true,
            id: generateUUID()
          };
          nonVoidDateArray = nonVoidedData;
          nonVoidDateArray.push(value);
          setNonVoidedData(nonVoidDateArray);
          handleCloseCrossReference();
        }
        const voiddata = voidedData;
        const nonVoidData = nonVoidedData;
        const concatBoth = voiddata.concat(nonVoidData);
        setConcatbothVoidAndNonVoid(concatBoth);

        if (showVoid) {
          setSystemListAddTableData(concatBoth);
        } else {
          setSystemListAddTableData(nonVoidData);
        }
      }
    } else {
      if (checkPopupFormValidations()) {
        let count = 0;

        if (nonVoidedData.length > 0) {
          nonVoidedData.map((value) => {
            if (
              nonVoidedData.indexOf(value) !==
              nonVoidedData.indexOf(editData) &&
              value.startingValue.toString() === systemListValues.beginValue &&
              systemListValues.voidSelected === 'No'
            ) {
              if (
                moment(value.beginDate).format('MM/DD/YYYY') ===
                moment(selectedBeginDate).format('MM/DD/YYYY')
              ) {
                count = count + 1;
              } else if (
                moment(value.beginDate).format('MM/DD/YYYY') <
                moment(selectedBeginDate).format('MM/DD/YYYY') &&
                moment(value.endDate).format('MM/DD/YYYY') >=
                moment(selectedEndDate).format('MM/DD/YYYY')
              ) {
                count = count + 1;
              } else if (
                moment(value.beginDate).format('MM/DD/YYYY') >
                moment(selectedBeginDate).format('MM/DD/YYYY') &&
                moment(selectedEndDate).format('MM/DD/YYYY') >=
                moment(value.beginDate).format('MM/DD/YYYY')
              ) {
                count = count + 1;
              }
            }
          });
        }

        let showDateOverlappingError = false;
        if (count > 0) {
          errorMessagesArray = [];
          setErrorMessages([]);
          showDateOverlappingError = true;
          errorMessagesArray.push(SystemListSearchConstants.OVERLAPPING_ERROR);
          setErrorMessages(errorMessagesArray);
          setShowError({ showOverLappingError: showDateOverlappingError });
          window.scrollTo(0, 0);
        } else {
          nonVoidedData[
            nonVoidedData.indexOf(editData)
          ].auditUserID = logInUserID;
          nonVoidedData[
            nonVoidedData.indexOf(editData)
          ].auditTimeStamp = getUTCTimeStamp();
          nonVoidedData[nonVoidedData.indexOf(editData)].startingValue =
            systemListValues.beginValue;
          nonVoidedData[nonVoidedData.indexOf(editData)].endingValue =
            values.listType === 'UIS'
              ? systemListValues.beginValue
              : systemListValues.endValue;
          nonVoidedData[nonVoidedData.indexOf(editData)].lineofbusiness =
            systemListValues.lob;
          nonVoidedData[nonVoidedData.indexOf(editData)].beginDate = moment(
            selectedBeginDate
          ).format('MM/DD/YYYY');
          nonVoidedData[nonVoidedData.indexOf(editData)].endDate = moment(
            selectedEndDate
          ).format('MM/DD/YYYY');
          nonVoidedData[nonVoidedData.indexOf(editData)].sortNum =
            systemListValues.sortNum;
          if (systemListValues.voidSelected === 'Yes') {
            nonVoidedData[nonVoidedData.indexOf(editData)].voidDate = moment(
              new Date()
            ).format('MM/DD/YYYY');
            nonVoidedData.map(value => {
              if (value.voidDate) {
                voidDateArray = voidedData;
                voidDateArray.push(value);
                setVoidedData(voidDateArray);
              } else if (!value.voidDate) {
                nonVoidDateArray.push(value);
                setNonVoidedData(nonVoidDateArray);
              }

              if (nonVoidedData.length === 1) {
                setNonVoidedData([]);
              }
            });
          }
        }
        const voiddata = voidDateArray;
        const nonVoidData =
          systemListValues.voidSelected === 'Yes'
            ? nonVoidDateArray
            : nonVoidedData;
        const concatBoth = voiddata.concat(nonVoidData);
        setConcatbothVoidAndNonVoid(concatBoth);
        if (showVoid) {
        } else {
          setSystemListAddTableData(nonVoidData);
        }

        handleCloseCrossReference();
        setAdd(true);
      }
    }
  };

  const handleShowVoid = () => {
    setFocusCheck(true);
    if (showVoid) {
      setShowVoid(false);
      setSystemListAddTableData(nonVoidedData);
    } else {
      setShowVoid(true);
      const voiddata = voidedData;
      const nonVoidData = nonVoidedData;
      const concatBoth = voiddata.concat(nonVoidData);
      setConcatbothVoidAndNonVoid(concatBoth);
      setSystemListAddTableData(concatBoth);
    }
  };

  const updateSelectedSystemList = updatedObj => {
    const tempOriginal = systemListAddTableData.slice();
    tempOriginal.map((value) => {
      if (value.id === updatedObj.id) {
        value.beginDate = updatedObj.beginDate;
        value.endDate = updatedObj.endDate;
        value.lob = updatedObj.lob;
        value.beginValue = updatedObj.beginValue;
        value.endValue = updatedObj.endValue;
      }
    });
    setSystemListAddTableData(tempOriginal);
  };
  const handleSystemListChanges = name => event => {
    setFocusCheck(true);
    setInitalFocusCheck(true);
    setAllowNavigation(true);
    setSystemListValues({ ...systemListValues, [name]: event.target.value });
  };
  const handleSystemListFilterChanges = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setInitalFocusCheck(true);
    setSystemListFilterValues({
      ...systemListFilterValues,
      [name]: event.target.value
    });
  };
  const [selectedBeginDate, setSelectedBeginDate] = React.useState('');
  const [selectedFilterBeginDate, setSelectedFilterBeginDate] = React.useState(
    null
  );
  const handleBeginDateChange = date => {
    setFocusCheck(true);
    setSelectedBeginDate(date);
    setInitalFocusCheck(true);
    setAllowNavigation(true);
    setSystemListValues({ ...systemListValues, beginDate: date });
  };
  const [selectedEndDate, setSelectedEndDate] = React.useState('');
  const [selectedFilterEndDate, setSelectedFilterEndDate] = React.useState(
    null
  );

  const handleEndDateChange = date => {
    setSelectedEndDate(date);
    setFocusCheck(true);
    setInitalFocusCheck(true);
    setAllowNavigation(true);
    setSystemListValues({ ...systemListValues, endDate: date });
  };

  const saveAddSystemList = () => {
    setSuccessMessages([]);

    if (allowNavigation === false) {
      NoSaveMessage();
    } else {
      if (checkFormValidations()) {
        const voidSet = voidedData;
        const nonVoidSet = nonVoidedData;
        if (nonVoidedData.length > 0) {
          const concatBoth = voidSet.concat(nonVoidSet);
          concatBoth.forEach(value => value.lineofbusiness = AppConstants.DEFAULT_LOB_VALUE);
          setSystemListAddTableData(concatBoth);
          const value = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID:
              payloadData && payloadData[0] && payloadData[0].addedAuditUserID
                ? payloadData[0].addedAuditUserID
                : logInUserID,
            addedAuditTimeStamp:
              payloadData &&
                payloadData[0] &&
                payloadData[0].addedAuditTimeStamp
                ? payloadData[0].addedAuditTimeStamp
                : getUTCTimeStamp(),
            versionNo: payloadData ? payloadData[0].versionNo : null,
            dbRecord: false,
            sortColumn: null,
            auditKeyList: [],
            auditKeyListFiltered: false,
            listNumber: values.listNumber,
            deletedSystemListDetails: [],
            description: values.description,
            functionalArea: values.functionalArea,
            listBusinessName: values.businessName,
            listType: values.listType,
            systemListDetails: concatBoth,
            dataElementName: values.dataElementName,
            groupAssignmentList: null,
            mapSetIds: [],
            noteSet: null,
            length: 0,
            format: null,
            columnName: null,
            sourceTableName: null,
            nameChange: null,
            columnScaleNumber: null,
            noteSetVO: notesInput
          };

          setErrorMessages([]);
          setSystemListAddTableData(nonVoidSet);

          onUpdateSystemList(value);
        } else {
          setErrorMessages([SystemListSearchConstants.DETAIL_ROW_REQUIRED]);
        }
      }
    }
  };

  const onClickeditSystemList = data => () => {
    setEditData(data);
    setAuditLogRow(data);
    setRetainEdit(data);
    setSuccessMessages([]);
    setErrorMessages([]);
    setOpenCrossReference(true);
    setSystemListValues({
      lob: data.lineofbusiness,
      beginValue: data.startingValue,
      endValue: data.endingValue,
      sortNum: data.sortNum,
      voidSelected: data.voidDate != null ? 'Yes' : 'No'
    });
    setCurrentRow(data);
    setSelectedEndDate(data.endDate);
    setSelectedBeginDate(data.beginDate);
    setAdd(false);
  };

  const applyFilter = () => {
    setIsFilterEnable(true);
    const filterObject = {};
    if (systemListFilterValues.lob !== DEFAULT_DD_VALUE) {
      filterObject.lineofbusiness = systemListFilterValues.lob;
    }
    if (systemListFilterValues.beginValue !== '') {
      filterObject.startingValue = systemListFilterValues.beginValue;
    }
    if (systemListFilterValues.endValue !== '') {
      filterObject.endingValue = systemListFilterValues.endValue;
    }
    if (
      selectedFilterBeginDate &&
      selectedFilterBeginDate !== '' &&
      selectedFilterBeginDate.toString() !== 'Invalid Date'
    ) {
      filterObject.beginDate = moment(selectedFilterBeginDate).format(
        'MM/DD/YYYY'
      );
    }
    if (
      selectedFilterEndDate &&
      selectedFilterEndDate !== '' &&
      selectedFilterEndDate.toString() !== 'Invalid Date'
    ) {
      filterObject.endDate = moment(selectedFilterEndDate).format('MM/DD/YYYY');
    }
    if (
      systemListFilterValues.lob === DEFAULT_DD_VALUE &&
      systemListFilterValues.beginValue === '' &&
      systemListFilterValues.endValue === '' &&
      selectedFilterBeginDate === '' &&
      selectedFilterEndDate === ''
    ) {
      setIsFilterEnable(false);
    } else {
      const filterKeys = Object.keys(filterObject);
      if (nonVoidedData && nonVoidedData.length > 0) {
        const tempFilterData = nonVoidedData.filter((value) => {
          return filterKeys.every(key => {
            if (filterObject[key] !== undefined && !filterObject[key].length) {
              return true;
            }
            return value[key] === filterObject[key];
          });
        });
        setTempNonVolatile(tempFilterData);
        setSystemListAddTableData(tempFilterData);
      }
      if (concatbothvoidandnonvoid && concatbothvoidandnonvoid.length > 0) {
        const tempFilterData = concatbothvoidandnonvoid.filter(
          (value) => {
            return filterKeys.every(key => {
              if (
                filterObject[key] !== undefined &&
                !filterObject[key].length
              ) {
                return true;
              }
              return value[key] === filterObject[key];
            });
          }
        );
        setTempConcat(tempFilterData);
        setSystemListAddTableData(tempFilterData);
      }
    }
  };

  const rowDeleteSystemListDetails = data => {
    setRowSystemListDetailData({
      ...rowSystemListDetailData,
      rowSystemListDetailData: data
    });
  };

  function systemListDetailRowDeleteClick () {
    let temNewDialogData = [...systemListAddTableData];
    if (rowSystemListDetailData.rowSystemListDetailData) {
      for (
        let i = 0;
        i < rowSystemListDetailData.rowSystemListDetailData.length;
        i++
      ) {
        if (rowSystemListDetailData.rowSystemListDetailData[i] !== undefined) {
          temNewDialogData = temNewDialogData.filter(
            payment =>
              payment.id !== rowSystemListDetailData.rowSystemListDetailData[i]
          );
        }
      }
    }
    setTableData(temNewDialogData);
    setSystemListAddTableData(temNewDialogData);
    setRowSystemListDetailData([]);
  }
  let notesDataArray = [];
  const addNotes = data => {
    setAllowNavigation(true);

    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  const [disableNotes, setDisableNotes] = React.useState(true);
  const validateParentForm = () => {
    if (
      !values.listType ||
      values.listType === DEFAULT_DD_VALUE ||
      !values.businessName || values.businessName === '' ||
      !values.dataElementName ||
      values.dataElementName === DEFAULT_DD_VALUE ||
      !values.description || values.description === ''
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [values]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSuccessMessages([]);
    setErrorMessages([]);
    setShowError(false);
  };
  useEffect(() => {
    if (openCrossReference && document.getElementById('form_pop_up')) {
      document.getElementById('form_pop_up').scrollIntoView();
    }
  }, [openCrossReference]);
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [auditLogRow, setAuditLogRow] = React.useState(null);
  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditTableData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditTableData([]);
    }
  }, [auditLogRow]);

  const ValuesFromSysList = props.history && props.history.location && props.history.location.state &&
    props.history.location.state.values;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/SystemList',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { ValuesFromSysList: ValuesFromSysList }
      });
    }, 100);
  };
  const majorCancel = () => {
    setErrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/SystemList',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { ValuesFromSysList: ValuesFromSysList }
      });
    }
  };
  const listTypeDataVal = listTypeData.find(value => value.code === values.listType);
  const funcVal = addDropdowns && addDropdowns['Reference#R_FUNC_AREA_CD'] &&
    addDropdowns['Reference#R_FUNC_AREA_CD'].find(value => value.code === values.functionalArea);

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
        when={allowNavigation}
        message={location =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />

      <div className="tabs-container">
       <ErrorMessages errorMessages={errorMessages} />

        {successMessages.length > 0 ? (
          <SuccessMessages successMessages={successMessages} />
        ) : null}
        <div className="tab-header">
          <div className="tab-heading float-left">Edit System List</div>
          <div className="float-right  mt-1 pt-1">
            <Button
              variant="outlined"
              color="primary"
              className="btn btn-success"
              onClick={saveAddSystemList}
              disabled={!userInquiryPrivileges}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button
              className={
                auditButtonToggle
                  ? 'btn btn-primary ml-1'
                  : 'btn btn-text1 btn-transparent ml-1'
              }
              onClick={() => {
                setAuditButtonToggle(!auditButtonToggle);
                setShowLogTable(true);
              }}
            >
              <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
              Audit Log
            </Button>
            {window.location.pathname === '/SystemListAddUpdate' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <div className="MuiCollapse-container MuiCollapse-entered">
            <div className="MuiCollapse-wrapper">
              <div className="w-100">
                <form
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-9">
                      <div className="form-wrapper">
                        {funcVal === undefined
                          ? <div
                            className="mui-custom-form with-select with-select override-width-28" // style={{ marginLeft: '54px' }}
                          >
                            <TextField
                              id="standard-select-functionalArea"
                              fullWidth
                              required
                              InputProps={{
                                readOnly: true,
                                className: 'Mui-disabled'
                              }}
                              label="Functional Area"
                              value={values.functionalArea}
                              placeholder=""
                              onChange={handleChanges('functionalArea')}

                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          : <NativeDropDown
                              id="standard-select-functionalArea"
                              isRequired
                              disabled
                              className='override-width-28'
                              label="Functional Area"
                              value={values.functionalArea}
                              placeholder=""
                              onChange={handleChanges('functionalArea')}
                              InputLabelProps={{
                                shrink: true
                              }}
                              errTxt={
                                showFunctionalAreaError
                                  ? showFunctionalAreaErrorText
                                  : null
                              }
                              dropdownList={addDropdowns && addDropdowns['Reference#R_FUNC_AREA_CD'] &&
                                addDropdowns['Reference#R_FUNC_AREA_CD'].length > 0
                                ? addDropdowns['Reference#R_FUNC_AREA_CD'].map((option) => (
                                  <option key={option.code} value={option.code}>
                                    {option.description}
                                  </option>
                                ))
                                : null}
                            />}
                        <div
                          className="mui-custom-form with-select with-select override-width-28" // style={{ marginLeft: '54px' }}
                        >
                          <TextField
                            id="standard-listNumber"
                            fullWidth
                            required
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            label="List Number"
                            type="number"
                            value={values.listNumber}
                            onChange={handleChanges('listNumber')}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            onInput={e => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                            onKeyDown={evt =>
                              evt.key === 'e' && evt.preventDefault()
                            }
                            helperText={
                              showListNumberError
                                ? showListNumberErrorText
                                : null
                            }
                            error={
                              showListNumberError
                                ? showListNumberErrorText
                                : null
                            }
                          />
                        </div>

                          <NativeDropDown
                            id="standard-select-dataelementname"
                            className='override-width-28'
                            isRequired
                            disabled={!userInquiryPrivileges}
                            label="Data Element Name"
                            value={values.dataElementName}
                            onChange={handleChanges('dataElementName')}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            errTxt={
                              showDataElementNameError
                                ? showDataElementNameErrorText
                                : null
                            }
                            dropdownList={dataElementNameData
                              ? dataElementNameData.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))
                              : null}
                          />
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form-description with-select override-width-62">
                          <TextField
                            id="standard-description"
                            fullWidth
                            label="Description"
                            required
                            InputProps={{
                              readOnly: !userInquiryPrivileges,
                              className: !userInquiryPrivileges
                                ? 'Mui-disabled'
                                : ''
                            }}
                            value={values.description}
                            onChange={handleChanges('description')}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{ maxLength: 320 }}
                            helperText={
                              showDescriptionError
                                ? showDescriptionErrorText
                                : null
                            }
                            error={
                              showDescriptionError
                                ? showDescriptionErrorText
                                : null
                            }
                          />
                        </div>

                          <NativeDropDown
                            id="standard-select-listtype"
                            className='override-width-28'
                            isRequired
                            disabled={!userInquiryPrivileges}
                            label="List Type"
                            value={listTypeDataVal !== undefined ? values.listType : DEFAULT_DD_VALUE}
                            onChange={handleChanges('listType')}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            errTxt={
                              showListTypeError ? showListtypeErrorText : null
                            }
                            dropdownList={listTypeData
                              ? listTypeData.map((option, index) => (
                                <option key={index} value={option.code}>
                                  {option.description}
                                </option>
                              ))
                              : null}
                          />
                        <div className="ShowoidCheckBox">
                          <div className="mui-custom-form">
                            <div className="sub-radio ">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    onChange={handleChange('antoine')}
                                    value="antoine"
                                  />
                                }
                                label="Show Voids"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form-description with-select with-select override-width-62">
                          <TextField
                            id="standard-businessName"
                            fullWidth
                            required
                            // disabled={!userInquiryPrivileges}
                            InputProps={{
                              readOnly: !userInquiryPrivileges,
                              className: !userInquiryPrivileges
                                ? 'Mui-disabled'
                                : ''
                            }}
                            label="Business Name"
                            value={values.businessName}
                            onChange={handleChanges('businessName')}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            helperText={
                              showBusinessNameError
                                ? showBusinessNameErrorText
                                : null
                            }
                            error={
                              showBusinessNameError
                                ? showBusinessNameErrorText
                                : null
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="form-wrapper">
                        <div
                          className="mui-custom-form with-select with-select w-100 mapsettablelist"
                          style={{ marginRight: '20px' }}
                        >
                          <TextField
                            // disabled
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            id="standard-mapid"
                            fullWidth
                            label="Used in Mapset"
                            value="Map ID"
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                          {MapIdList.length > 0 ? (
                            <MapsetIdTable
                              MapIdList={MapIdList}
                              redirectToMapset={redirectToMapset}
                              openMapsetPopUpDialog={openMapsetPopUpDialog}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div></div>
          <div className="tab-panelbody m-0">
            <div className="tab-holder">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="System List Detail" />
                  <Tab label="Notes" />
                </Tabs>
              </AppBar>
              <TabPanel
                value={value}
                index={0}
              >
                <div className="tab-holder p-0">
                  {value === 0 && (
                    <>
                      <div
                        className="rounded-top"
                        onClick={() =>
                          setShowAdditionalDetails1(!showAdditionalDetails1)
                        }
                        style={{
                          backgroundColor: '#274463',
                          color: '#fff',
                          padding: '10px',
                          margin: '10px 0px',
                          fontWeight: '500',
                          fontSize: '14px'
                        }}
                      >
                        Display Filters
                        <div className="pull-right">
                          {showAdditionalDetails1 ? (
                            <span>
                              <i className="fa fa-angle-up" aria-hidden="true"></i>
                            </span>
                          ) : (
                            <span>
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {(() => {
                    if (showAdditionalDetails1) {
                      return (
                        <form autoComplete="off">
                          <div className="form-wrapper">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <div className="mui-custom-form with-select with-select input-md">
                                <KeyboardDatePicker
                                  maxDate={Date.parse(
                                    '31 Dec 9999 00:00:00 GMT'
                                  )}
                                  id="date-picker-dialog3"
                                  fullWidth
                                  label="Begin Date"
                                  format="MM/dd/yyyy"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  placeholder="mm/dd/yyyy"
                                  value={selectedFilterBeginDate}
                                  onChange={setSelectedFilterBeginDate}
                                  error={
                                    showBeginDateError
                                      ? showBeginDateErrorText
                                      : null
                                  }
                                  helperText={
                                    showBeginDateError
                                      ? showBeginDateErrorText
                                      : null
                                  }
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                  }}
                                />
                              </div>

                              <div className="mui-custom-form with-select with-select input-md">
                                <KeyboardDatePicker
                                  id="date-picker-dialog4"
                                  fullWidth
                                  label="End Date"
                                  helperText={null}
                                  format="MM/dd/yyyy"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  error={false}
                                  maxDate={
                                    new Date('9999-12-31T13:00:00.000+0000')
                                  }
                                  placeholder="mm/dd/yyyy"
                                  value={selectedFilterEndDate}
                                  onChange={setSelectedFilterEndDate}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                  }}
                                />
                              </div>
                            </MuiPickersUtilsProvider>
                            <div className="mui-custom-form with-select with-select input-md">
                              <TextField
                                id="beginValue"
                                label="Begin Value"
                                value={systemListFilterValues.beginValue}
                                onChange={handleSystemListFilterChanges(
                                  'beginValue'
                                )}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </div>
                            <div className="mui-custom-form with-select with-select input-md">
                              <TextField
                                id="endValue"
                                fullWidth
                                label="End Value"
                                value={systemListFilterValues.endValue}
                                onChange={handleSystemListFilterChanges(
                                  'endValue'
                                )}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </div>
                            <div
                              className="mui-custom-form with-select with-select input-md"
                              style={{ marginTop: '34px' }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-primary"
                                onClick={() => {
                                  applyFilter();
                                }}
                              >
                                Filter
                              </Button>
                            </div>
                          </div>
                        </form>
                      );
                    }
                  })()}
                  {value === 0 && (
                    <>
                      <div style={{ marginBottom: '19px' }}>
                        <div className="mui-custom-form no-margin dib show-voids mt-3 mt-1">
                          <div className="sub-radio no-margin">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={showVoid}
                                  value={showVoid}
                                  onChange={handleShowVoid}
                                />
                              }
                              label="Show Voids"
                            />
                          </div>
                        </div>

                        <div className="float-right my-1">
                          <Button
                            className="btn-text-main-delete btn-transparent  ml-1"
                            onClick={systemListDetailRowDeleteClick}
                            title={'Delete'}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                            Delete
                          </Button>

                          <Button
                            variant="outlined"
                            color="primary"
                            className="float-right btn-text-main-add btn-success  ml-1"
                            onClick={() => addSystemListDetails()}
                            disabled={!userInquiryPrivileges}
                            title={'Add System List Detail'}
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i> Add
                          </Button>
                        </div>
                      </div>
                    </>
                  )}

                  {value === 0 && (
                    <>
                      <div className="clearfix"></div>
                      <div className="tab-holder my-3 p-0">
                        <SystemListAddTable
                          tableData={systemListAddTableData || []}
                          showVoid={showVoid}
                          voidTableData={
                            isFilterEnable
                              ? setTempConcat
                              : concatbothvoidandnonvoid
                          }
                          nonVoidTableData={
                            isFilterEnable ? tempNonVolatile : nonVoidedData
                          }
                          updateSystemList={updateSelectedSystemList}
                          editSystemListTable={onClickeditSystemList}
                          rowDeleteSystemListDetails={
                            rowDeleteSystemListDetails
                          }
                          onClickAuditDialogLog={onClickAuditDialogLog}
                          setAuditLogRow={setAuditLogRow}
                          auditRowData={auditTableData}
                          setAuditRowData={setAuditTableData}
                          setShowLogTable={setShowLogDialogTable}
                          showLogDialogTable={showLogDialogTable}
                          uniqueKey={'listDetail'}
                          setClose={setClose}
                          close={close}
                          dropdows={addDropdowns}
                        />
                        {
                          openCrossReference
                            ? <div className="tabs-container" id='form_pop_up'>
                              <div className="tab-header  mini-tab-header">
                                <div className="tab-heading float-left">
                                  {
                                    add ? 'Add System List Details' : 'Edit System List Details'
                                  }
                                </div>
                                <div className="float-right mt-1">
                                  {add ? (
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      className="btn btn-success ml-1"
                                      onClick={addSystemListValuesToTable}
                                      disabled={!userInquiryPrivileges}
                                    >
                                      <i className="fa fa-check" aria-hidden="true"></i>Add
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      className="btn btn-success"
                                      onClick={addSystemListValuesToTable}
                                      disabled={
                                        !userInquiryPrivileges
                                          ? !userInquiryPrivileges
                                          : !!(
                                            currentRow !== undefined &&
                                            currentRow.voidDate != null
                                          )
                                      }
                                    >
                                      <i className="fa fa-check" aria-hidden="true"></i>
                                      Update
                                    </Button>
                                  )}

                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    className="bt-reset btn-transparent  ml-1"
                                    onClick={resetSystemListValues}
                                    disabled={
                                      !userInquiryPrivileges
                                        ? !userInquiryPrivileges
                                        : !!(
                                          currentRow !== undefined &&
                                          currentRow.voidDate != null
                                        )
                                    }
                                  >
                                    <i className="fa fa-undo" aria-hidden="true"></i>Reset
                                  </Button>
                                  <Button
                                    variant="outlined" color="primary" className='btn btn-primary btn btn-outlined ml-1'
                                    onClick={handleCloseCrossReference}
                                  >
                                    Cancel
                                  </Button>

                                </div>
                              </div>
                              <div className="form-border my-3">
                                <form autoComplete="off">
                                  <div className="form-wrapper">
                                    {values.listType === 'UIS' ? (
                                      <div className="mui-custom-form">
                                        <TextField
                                          InputProps={{
                                            readOnly: !userInquiryPrivileges
                                              ? !userInquiryPrivileges
                                              : !!(
                                                currentRow !== undefined &&
                                                currentRow.voidDate != null
                                              ),
                                            className: (!userInquiryPrivileges
                                              ? !userInquiryPrivileges
                                              : !!(
                                                currentRow !== undefined &&
                                                currentRow.voidDate != null
                                              ))
                                              ? 'Mui-disabled'
                                              : ''
                                          }}
                                          id="constant-text"
                                          fullWidth
                                          label="Sort Order"
                                          value={systemListValues.sortNum}
                                          onChange={handleSystemListChanges('sortNum')}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          inputProps={{ maxLength: 10 }}
                                          onInput={e => {
                                            e.target.value = e.target.value.replace(
                                              AppConstants.NUMBER_ONLY_REGEX,
                                              ''
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : null}

                                    <div className="mui-custom-form">
                                      <TextField
                                        id="beginValue"
                                        fullWidth
                                        label="Begin Value"
                                        required
                                        inputProps={{ maxLength: 30 }}

                                        InputProps={{
                                          readOnly: !userInquiryPrivileges
                                            ? !userInquiryPrivileges
                                            : !!(
                                              currentRow !== undefined &&
                                              currentRow.voidDate != null
                                            ),
                                          className: (!userInquiryPrivileges
                                            ? !userInquiryPrivileges
                                            : !!(
                                              currentRow !== undefined &&
                                              currentRow.voidDate != null
                                            ))
                                            ? 'Mui-disabled'
                                            : ''
                                        }}
                                        value={systemListValues.beginValue}
                                        onChange={handleSystemListChanges('beginValue')}
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                        helperText={
                                          showBeginValueError ||
                                            showRevenueCodeBeginValueError
                                            ? showBeginValueErrorText
                                            : showDiagNumericBeginValueError
                                              ? showDiagNumericBeginValueErrorText
                                              : null
                                        }
                                        error={
                                          showBeginValueError ||
                                            showRevenueCodeBeginValueError
                                            ? showBeginValueErrorText
                                            : showDiagNumericBeginValueError
                                              ? showDiagNumericBeginValueErrorText
                                              : null
                                        }
                                      />
                                    </div>

                                    {values.listType === 'PRC' || values.listType === 'XWK' ? (
                                      <div className="mui-custom-form">
                                        <TextField
                                          id="endValue"
                                          fullWidth
                                          label="End Value"
                                          required
                                          inputProps={{ maxLength: 30 }}
                                          InputProps={{
                                            readOnly: !userInquiryPrivileges
                                              ? !userInquiryPrivileges
                                              : !!(
                                                currentRow !== undefined &&
                                                currentRow.voidDate != null
                                              ),
                                            className: (!userInquiryPrivileges
                                              ? !userInquiryPrivileges
                                              : !!(
                                                currentRow !== undefined &&
                                                currentRow.voidDate != null
                                              ))
                                              ? 'Mui-disabled'
                                              : ''
                                          }}
                                          value={systemListValues.endValue}
                                          onChange={handleSystemListChanges('endValue')}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          helperText={
                                            showEndValueError || showRevenueCodeEndValueError
                                              ? showEndValueErrorText
                                              : showDiagNumericEndValueError
                                                ? showDiagNumericEndValueErrorText
                                                : null
                                          }
                                          error={
                                            showEndValueError || showRevenueCodeEndValueError
                                              ? showEndValueErrorText
                                              : showDiagNumericEndValueError
                                                ? showDiagNumericEndValueErrorText
                                                : null
                                          }
                                        />
                                      </div>
                                    ) : null}
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <div className="mui-custom-form with-date">
                                        <KeyboardDatePicker
                                          maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                          id="date-picker-dialog1"
                                          fullWidth
                                          label="Begin Date"
                                          required
                                          disabled={
                                            !userInquiryPrivileges
                                              ? !userInquiryPrivileges
                                              : !!(
                                                currentRow !== undefined &&
                                                currentRow.voidDate != null
                                              )
                                          }
                                          InputProps={{
                                            readOnly: !userInquiryPrivileges
                                              ? !userInquiryPrivileges
                                              : !!(
                                                currentRow !== undefined &&
                                                currentRow.voidDate != null
                                              ),
                                            className: (!userInquiryPrivileges
                                              ? !userInquiryPrivileges
                                              : !!(
                                                currentRow !== undefined &&
                                                currentRow.voidDate != null
                                              ))
                                              ? 'Mui-disabled'
                                              : ''
                                          }}
                                          format="MM/dd/yyyy"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          placeholder="mm/dd/yyyy"
                                          value={selectedBeginDate}
                                          onChange={handleBeginDateChange}
                                          error={false}
                                          helperText={null}
                                          KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                          }}
                                          helperText={
                                            showBeginDateError ? showBeginDateErrorText : null
                                          }
                                          error={
                                            showBeginDateError ? showBeginDateErrorText : null
                                          }
                                        />
                                      </div>
                                      {values.listType === 'PRC' || values.listType === 'XWK' ? (
                                        <div className="mui-custom-form with-date">
                                          <KeyboardDatePicker
                                            id="date-picker-dialog2"
                                            fullWidth
                                            label="End Date"
                                            required
                                            disabled={
                                              !userInquiryPrivileges
                                                ? !userInquiryPrivileges
                                                : !!(
                                                  currentRow !== undefined &&
                                                  currentRow.voidDate != null
                                                )
                                            }
                                            InputProps={{
                                              readOnly: !userInquiryPrivileges
                                                ? !userInquiryPrivileges
                                                : !!(
                                                  currentRow !== undefined &&
                                                  currentRow.voidDate != null
                                                ),
                                              className: (!userInquiryPrivileges
                                                ? !userInquiryPrivileges
                                                : !!(
                                                  currentRow !== undefined &&
                                                  currentRow.voidDate != null
                                                ))
                                                ? 'Mui-disabled'
                                                : ''
                                            }}
                                            format="MM/dd/yyyy"
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            maxDate={new Date('9999-12-31T13:00:00.000+0000')}
                                            placeholder="mm/dd/yyyy"
                                            value={selectedEndDate}
                                            onChange={handleEndDateChange}
                                            KeyboardButtonProps={{
                                              'aria-label': 'change date'
                                            }}
                                            helperText={
                                              showEndDateError ? showEndDateErrorText : null
                                            }
                                            error={
                                              showEndDateError ? showEndDateErrorText : null
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <div className="mui-custom-form with-date">
                                          <KeyboardDatePicker
                                            id="date-picker-dialog2"
                                            fullWidth
                                            label="End Date"
                                            required
                                            disabled={
                                              !userInquiryPrivileges
                                                ? !userInquiryPrivileges
                                                : !!(
                                                  currentRow !== undefined &&
                                                  currentRow.voidDate != null
                                                )
                                            }
                                            format="MM/dd/yyyy"
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            maxDate={new Date('9999-12-31T13:00:00.000+0000')}
                                            placeholder="mm/dd/yyyy"
                                            value={selectedEndDate}
                                            onChange={handleEndDateChange}
                                            KeyboardButtonProps={{
                                              'aria-label': 'change date'
                                            }}
                                            helperText={
                                              showEndDateError ? showEndDateErrorText : null
                                            }
                                            error={
                                              showEndDateError ? showEndDateErrorText : null
                                            }
                                          />
                                        </div>
                                      )}
                                    </MuiPickersUtilsProvider>
                                    {add || currentRow.isNewRow ? null : (
                                      <div className="mui-custom-form">
                                        <span className="MuiFormLabel-root small-label no-margin">
                                          Void
                                        </span>
                                        <div className="sub-radio"
                                          style={{ marginLeft: '0px' }}
                                        >
                                          <Radio
                                            name="voidSelection"
                                            value="Yes"
                                            id="voidYesId"
                                            disabled={
                                              !userInquiryPrivileges
                                                ? !userInquiryPrivileges
                                                : !!(
                                                  currentRow !== undefined &&
                                                  currentRow.voidDate != null
                                                )
                                            }
                                            checked={systemListValues.voidSelected === 'Yes'}
                                            onChange={handleSystemListChanges('voidSelected')}
                                          />
                                          <label className="text-black" htmlFor="voidYesId">
                                            Yes
                                          </label>
                                          <Radio
                                            name="voidSelection"
                                            value="No"
                                            id="voidNoId"
                                            disabled={
                                              !userInquiryPrivileges
                                                ? !userInquiryPrivileges
                                                : !!(
                                                  currentRow !== undefined &&
                                                  currentRow.voidDate != null
                                                )
                                            }
                                            checked={systemListValues.voidSelected === 'No'}
                                            onChange={handleSystemListChanges('voidSelected')}
                                            className="ml-2"
                                          />
                                          <label className="text-black" htmlFor="voidNoId">
                                            No
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </form>
                                {
                                  (showLogDialogTable)
                                    ? (<div id='audit-dialog-table'>
                                      <AuditTableComponent
                                        print
                                        auditRowData={auditTableData}
                                        setAuditRowData={setAuditTableData}
                                        setShowLogTable={setShowLogDialogTable}
                                        setClose={setClose}
                                      /> </div>) : null
                                }
                              </div>
                            </div>
                            : null}
                      </div>
                    </>
                  )}
                  {
                    showLogTable
                      ? (<div id='audit-table'>
                        <AuditTableComponent
                          print
                          auditRowData={auditRowData}
                          setAuditRowData={setAuditRowData}
                          setShowLogTable={setShowLogTable}
                        /> </div>) : null
                  }
                </div>
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
              >
                {value === 1 && (
                  <div className="tab-holder my-3 p-0">
                    <Notes
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      disableNotes={disableNotes}
                      isNotesTable={true}
                      setErrorMessages={setErrorMessages}
                      userInquiryPrivileges={userInquiryPrivileges}
                      setFocusCheck={setFocusCheck}
                    />
                  </div>
                )}

              </TabPanel>
              {showSystemlistValueTable ? (
                <div>
                  <div className="tabs-container">
                    <div className="tab-header">
                      <div className="tab-heading float-left">
                        Edit List Value
                      </div>
                      <form autoComplete="off">
                        <div className="clearfix"></div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}
              <Footer print />
              <Dialog
                className="custom-dialog dialog-500 with-padding"
                fullWidth={true}
                maxWidth={'sm'}
                open={openMapsetDialog}
                disableBackdropClick
              >
                <DialogContent dividers>
                  <div>Are you sure you want go to Mapset Edit Page?</div>
                </DialogContent>

                <DialogActions>
                  <Button
                    className="btn btn-primary ml-1"
                    onClick={() => redirectPageToMapsetAdd()}
                  >
                    OK
                  </Button>
                  <Button
                    className="btn btn-primary ml-1"
                    onClick={closeMapsetPopUpDialog}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="custom-alert-box"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <>
                      Unsaved changes will be lost. <br />
                      Are you sure you want to continue?
                    </>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDialogOpen(false);
                    }}
                    color="primary"
                    className="btn btn-transparent"
                  >
                    STAY ON THIS PAGE!
                  </Button>
                  <Button
                    onClick={cancelMinorSection}
                    color="primary"
                    className="btn btn-success"
                    autoFocus
                  >
                    CONTINUE <i className="fa fa-arrow-right ml-1" />
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
