/* eslint-disable no-dupe-keys */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { Link, Prompt } from 'react-router-dom';
import { redirectSystemID } from '../../../SharedModules/TabRedirections/TabRedirections';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../SharedModules/Errors/SuccessMessage';

import * as AppConstants from '../../../SharedModules/AppConstants';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import '../ReceiptReissue.scss';
import moment from 'moment';
import TabPanel from '../../../SharedModules/TabPanel/TabPanel';
import * as ReceiptReissueConstant from './ReceiptReissueConstant';
import CheckReplacementTabDetail from './CheckReplacementTabDetail';
import { validateReplacement } from './ReceiptReissueValidations';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  ReceiptDropdownActions,
  saveReissueCheckReplacement,
  systemListReceiptAction
} from '../Store/Actions/ReceiptReissueActions';
import dropdownCriteria from './Receipt.json';
import Notes from '../../../SharedModules/Notes/Notes';
import {
  generateUUID,
  getUTCTimeStamp,
  getDateInMMDDYYYYFormatWithApendZero
} from '../../../SharedModules/DateUtilities/DateUtilities';
import { refreshAttachmentsAction } from '../../../SharedModules/store/Actions/SharedAction';
import { systemListViewAction } from "../../ApplicationConfiguration/Store/Actions/systemList/systemListActions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import Footer from '../../../SharedModules/Layout/footer';
import Attachments from '../../../SharedModules/Attachments/Attachments';
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';
/* ReissueUpdate()
* This component is used to display Claims Receivab
le details
*/
function ReissueUpdate (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const userInquiry = !global.globalIsReadOnly();
  // Attachments variables
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  let refreshAttachmentResult = useSelector(state => state.sharedState.newAttachmentResult);

  const reasonCdValues = (values) => dispatch(systemListViewAction(values));
  let getReasonCds = useSelector(state => state.appConfigState.systemListState.systemListViewData);
  getReasonCds = getReasonCds && getReasonCds[0]?.systemListDetails?.map(code => code.startingValue);

  const receiptValues = (values) => dispatch(systemListReceiptAction(values));
  let receiptReasonCds = useSelector(state => state.receiptReissueState.rowReceiptSyslist);
  receiptReasonCds = receiptReasonCds && receiptReasonCds[0]?.systemListDetails?.map(code => code.startingValue);

  const fundRsnCode18 = getReasonCds && receiptReasonCds && getReasonCds.filter(x => !receiptReasonCds.includes(x)).toString();

  const fundRsnCode17 = getReasonCds && receiptReasonCds && receiptReasonCds.filter(x => getReasonCds.includes(x));

  const [saveFlag, setSaveFlag] = React.useState(false);
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const [isFocusCheck, setFocusCheck] = React.useState(false);
  const onReplacementSave = (value, index) =>
    dispatch(
      saveReissueCheckReplacement(
        value,
        attachmentsTableData &&
          index >= 0 &&
          attachmentsTableData[index] &&
          attachmentsTableData[index].id
          ? attachmentFile
          : null
      )
    );
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const sucessMessages = [];
  const systemErrorMessges = [];
  const reasonCodeDD = props.location.state.reasonCodeDD;
  const atrNumber = props.location.state.atrNumber;
  var errorMessagesArray = [];
  const [value, setValue] = React.useState(0);

  const [selectedCheckDate, setSelectedCheckDate] = React.useState(null);
  const [checkDatePress, setCheckDatePress] = React.useState(false);
  let replacementValidationErrors = [];
  let replacementData = [];
  const [reasonCodeList, setReasonCodeList] = React.useState([]);
  const [memberIDTypeDD, setMemberIDTypeDD] = React.useState([]);
  const [fieldsDisabled, setFieldsDisabled] = React.useState(false);
  const [fieldsDisabledSave, setFieldsDisabledSave] = React.useState(false);
  const [
    { showReasonCodeError, showCheckDateError, showCheckNumberError, showATRError },
    setShowError
  ] = React.useState(false);
  const [
    {
      showReasonCodeErrorText,
      showCheckDateErrorText,
      showCheckNumberErrorText
    },
    setShowErrorText
  ] = React.useState('');

  const saveReplacementResponse = useSelector(
    state => state.receiptReissueState.saveReplacement
  );
  const dropDownDispatch = dropdownvalues =>
    dispatch(ReceiptDropdownActions(dropdownvalues));
  const dropdown = useSelector(
    state => state.receiptReissueState.receiptDropdown
  );

  if (props.location.state.checkReplacementData !== undefined) {
    replacementData = props.location.state.checkReplacementData;
  }
  const clearSuccessErrorMsgs = () => {
    setShowError([]);
    setShowErrorText('');
    setErrorMessages([]);
    setSystemSuccesMessages([]);
  };

  const [retainFttcnCheck, setRetainFttcnCheck] = React.useState('');

  const dropdownData = props.location.state.dropdowns;
  const entityIDTypeCode = replacementData?.receiptVO?.entityType;
  const payeeTypeIDDropdown = entityIDTypeCode === 'P' ? 'P1#P_ALT_ID_TY_CD'
      : entityIDTypeCode === 'M' ? 'F1#B_ALT_ID_TY_CD'
      : entityIDTypeCode === 'O' ? 'F1#G_ENTY_EXTL_ID_TY_CD'
      : entityIDTypeCode === 'TC' ? 'F1#DM_G_CARR_ENTY_ID_TY_CD' : null;
  /* Setting initial values */
  const [values, setValues] = React.useState({
    lob: '',
    payeeType: '',
    payeeIdTypeCode: '',
    payeeId: '',
    payeePayerName: '',
    systemPayeeId: '',
    memberId: '',
    memberIdType: '',
    memberName: '',
    checkNumber: '',
    reasonCode: '',
    fcn: '',
    ftTcn: '',
    checkAmount: '',
    originalCheckNumber: '',
    originalCheckAmount: '',
    fcnCheck: '',
    finanReasonCode: DEFAULT_DD_VALUE,
    ftTcnCheck: '',
    atrNumber: ''
  });

  /* Display success/error message after updating check replacement details based on the response and response description coming from the API */
  useEffect(() => {
    setAllowNavigation(false);
    if (
      saveReplacementResponse &&
      saveReplacementResponse.respcode === '03' &&
      saveReplacementResponse.respdesc !== ''
    ) {
      const error = saveReplacementResponse.respdesc;
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      setErrorMessages(systemErrorMessges);
    }
    if (
      saveReplacementResponse &&
      saveReplacementResponse.systemFailure
    ) {
      const error = AppConstants.ERR_PROCESSING_REQ;
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      setErrorMessages(systemErrorMessges);
    }
    if (
      saveReplacementResponse &&
      saveReplacementResponse.respcode === '01' &&
      saveReplacementResponse.respdesc !== ''
    ) {
      const success = AppConstants.SAVE_SUCCESS;
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      sucessMessages.push(success);
      setSystemSuccesMessages(sucessMessages);
      setSaveFlag(true);
      setFieldsDisabledSave(true);
      dispatch(refreshAttachmentsAction(replacementData.receiptVO.checkReplacementVO.replacedFCN));
    }
    if (
      saveReplacementResponse &&
      saveReplacementResponse.respcode === '02' &&
      saveReplacementResponse.respdesc !== ''
    ) {
      const error = saveReplacementResponse.respdesc;
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      setErrorMessages(systemErrorMessges);
    }
    if (
      saveReplacementResponse != null &&
      (saveReplacementResponse.errorMessages !== undefined ||
        saveReplacementResponse.message !== undefined)
    ) {
      if (
        saveReplacementResponse.errorMessages &&
        saveReplacementResponse.errorMessages ===
        ReceiptReissueConstant.NETWORK_ERROR
      ) {
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        errorMessagesArray.push(ReceiptReissueConstant.TRANSACTION_ERROR);
        setErrorMessages(errorMessagesArray);
      } else if (
        saveReplacementResponse &&
        saveReplacementResponse.message &&
        saveReplacementResponse.message === ReceiptReissueConstant.NETWORK_ERROR
      ) {
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        errorMessagesArray.push(ReceiptReissueConstant.TRANSACTION_ERROR);
        setErrorMessages(errorMessagesArray);
      }
    }
  }, [saveReplacementResponse]);

  useEffect(() => {
    dropDownDispatch(dropdownCriteria);
    reasonCdValues(Dropdowns.RSN_CD_3001);
    receiptValues(Dropdowns.RSN_CD_3002);
    refreshAttachmentResult = null;
  }, []);

  useEffect(() => {
    if (refreshAttachmentResult && !refreshAttachmentResult.systemFailure) {
      setAttachmentsTableData(refreshAttachmentResult);
    }
  }, [refreshAttachmentResult]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Financial#3001']) {
        setReasonCodeList(dropdown.listObj['Financial#3001']);
      }
      if (dropdown.listObj['Financial#2']) {
        setMemberIDTypeDD(dropdown.listObj['Financial#2']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
    }
  }, [dropdown]);

  /* Setting field values if replacement data coming from props
   * If balance amount is "0" disbaled all the fields
   * Save button will also disabled
   */
  useEffect(() => {
    if (replacementData) {
      setFieldValues();
      setRetainFttcnCheck(replacementData.receiptVO.checkReplacementVO.relatedFTTCN);
      if (
        replacementData.receiptVO.balanceAmount === '0' ||
        replacementData.receiptVO.balanceAmount === '0.00' ||
        replacementData.receiptVO.balanceAmount === '0.0'
      ) {
        setFieldsDisabled(true);
      }
    }
  }, [replacementData]);

  /* This function is setting values */
  const setFieldValues = () => {
    setValues({
      lob: replacementData.receiptVO.lob,
      payeeType: replacementData.receiptVO.entityType,
      payeeIdTypeCode: replacementData.receiptVO.entityIDTypeCode,
      payeeId: replacementData.receiptVO.entityID,
      payeePayerName: replacementData.receiptVO.entityName,
      systemPayeeId: replacementData.receiptVO.sysID,
      memberId: replacementData.receiptVO.memberID,
      memberIdType: replacementData.receiptVO.memberIDType,
      memberName: replacementData.receiptVO.memberName,
      checkNumber: replacementData.receiptVO.checkReplacementVO.checkNumber,
      reasonCode: replacementData.receiptVO.financialReason,
      fcn: replacementData.receiptVO.fcn,
      ftTcn: replacementData.receiptVO.relatedFTTCN,
      checkAmount:
        replacementData.receiptVO.checkReplacementVO.replacedChkAmount,
      originalCheckNumber: replacementData.receiptVO.checkNumber,
      originalCheckAmount: replacementData.receiptVO.originalAmount,
      fcnCheck: replacementData.receiptVO.checkReplacementVO.replacedFCN,
      finanReasonCode:
        replacementData.receiptVO.checkReplacementVO.chckReplaceRsnCd !== '' &&
          replacementData.receiptVO.checkReplacementVO.chckReplaceRsnCd != null
          ? replacementData.receiptVO.checkReplacementVO.chckReplaceRsnCd.split(
            '-'
          )[0]
          : DEFAULT_DD_VALUE,
      ftTcnCheck: replacementData.receiptVO.checkReplacementVO.relatedFTTCN,
      noteSetVO: notesInput
    });
    setSelectedCheckDate(
      replacementData.receiptVO.checkReplacementVO.checkDate
    );

    if (replacementData.receiptVO.noteSetVO) {
      const noteSetVO = replacementData.receiptVO.noteSetVO;
      const notesTable = replacementData.receiptVO.noteSetVO.notesList;
      setNotesInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: noteSetVO.auditUserID
          ? noteSetVO.auditUserID
          : logInUserID,
        addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
          ? noteSetVO.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: noteSetVO.versionNo,
        dbRecord: noteSetVO.dbRecord,
        sortColumn: noteSetVO.sortColumn,
        tableName: noteSetVO.tableName,
        noteSetSK: noteSetVO.noteSetSK,
        noteSourceName: noteSetVO.noteSourceName,
        notesList: notesTableData,
        globalNotesList: [],
        checkAll: noteSetVO.checkAll,
        addNewLinkRender: noteSetVO.addNewLinkRender,
        filterLinkRender: noteSetVO.filterLinkRender,
        printLinkRender: noteSetVO.printLinkRender,
        completeNotesList: []
      });

      setNotesTableData(notesTable);
    }
    setAttachmentFile([]);
  };

  /* This function is called on tab changes */
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  /* This function is called on change in any field on screen
   *  Setting filed values based on field type
   *  displaying unsaved changes will be lost pop-up if something change on screen
   */
  const handleChange = name => event => {
    setAllowNavigation(true);
    if (name === 'finanReasonCode') {
      if (!getReasonCds.includes(event.target.value)) {
        setShowError([]);
        setShowErrorText('');
        setErrorMessages([]);
        setValues({ ...values, [name]: event.target.value, checkNumber: '', ftTcnCheck: null });
        setSelectedCheckDate(null);
      } else {
        setValues({ ...values, [name]: event.target.value, ftTcnCheck: retainFttcnCheck });
      }
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  /* This function is used to set check date using date picker */
  const handleCheckDateChange = date => {
    setSelectedCheckDate(date);
    setCheckDatePress(false);
    setAllowNavigation(true);
  };

  /* This function is used to set check date using keyboard or key press */
  const handleCheckDateText = checkDateText => {
    setSelectedCheckDate(checkDateText.target.value);
    setCheckDatePress(true);
    setAllowNavigation(true);
  };

  /* This function is used to save check replacement by hitting save API
   * If all required fields entered and validations passes, hit the API
   */
  const saveCheckReplacement = values => {
    replacementValidationErrors = validateReplacement(
      values,
      selectedCheckDate
    );
    if (replacementValidationErrors.errorMessagesArray.length > 0) {
      setErrorMessages([]);
      setErrorMessages(replacementValidationErrors.errorMessagesArray);
      setShowError({
        showReasonCodeError:
          replacementValidationErrors.setErrors.showReasonCodeError,
        showCheckDateError:
          replacementValidationErrors.setErrors.showCheckDateError,
        showCheckNumberError:
          replacementValidationErrors.setErrors.showCheckNumberError,
          showATRError:
          replacementValidationErrors.setErrors.showATRError
      });

      setShowErrorText({
        showReasonCodeErrorText:
          replacementValidationErrors.setErrorText.showReasonCodeErrorText,
        showCheckDateErrorText:
          replacementValidationErrors.setErrorText.showCheckDateErrorText,
        showCheckNumberErrorText:
          replacementValidationErrors.setErrorText.showCheckNumberErrorText
      });
    } else {
      setErrorMessages([]);
      setShowError(false);

      for (var rsnCode in reasonCodeList) {
        if (reasonCodeList[rsnCode].code === values.finanReasonCode) {
          replacementData.receiptVO.checkReplacementVO.chckReplaceRsnCd =
            reasonCodeList[rsnCode].description;
        }
      }
      replacementData.receiptVO.checkReplacementVO.checkNumber =
        values.checkNumber;
      if (selectedCheckDate) {
        replacementData.receiptVO.checkReplacementVO.checkDate = moment(
          selectedCheckDate
        ).format('MM/DD/YYYY');
      } else {
        replacementData.receiptVO.checkReplacementVO.checkDate = '';
        replacementData.receiptVO.checkReplacementVO.checkNumber = '';
      }
      replacementData.receiptVO.checkReplacementVO.reasonCode =
        values.finanReasonCode;
      replacementData.receiptVO.checkReplacementVO.originalcheckNumber =
        replacementData.receiptVO.checkNumber;
      replacementData.receiptVO.noteSetVO = notesInput;
      replacementData.receiptVO.atrNumber = values?.atrNumber?.toUpperCase();
      replacementData.receiptVO.checkReplacementVO.relatedFTTCN = values.ftTcnCheck;
      replacementData.receiptVO.checkReplacementVO.fcnSeqNum = replacementData
        .receiptVO.fcn
        ? replacementData.receiptVO.fcn
          .toString()
          .slice(
            replacementData.receiptVO.fcn.toString().length - 5,
            replacementData.receiptVO.fcn.toString().length
          )
        : 49296;
      const index = attachmentsTableData.length - 1;
      const attachmentVOList = [];
      if (attachmentsTableData && attachmentsTableData.length > 0) {
        attachmentsTableData.map((value) => {
          if (value.id) {
            const attachmentVO = {
              auditUserID: logInUserID,
              auditTimeStamp: getUTCTimeStamp(),
              addedAuditUserID: logInUserID,
              addedAuditTimeStamp: getUTCTimeStamp(),
              versionNo: 0,
              dbRecord: true,
              sortColumn: null,
              attachmentSK: null,
              attachmentPageID: null,
              finalPath: null,
              detachInd: false,
              historyIndicator: false,
              cascadeKey: null,
              attachmentIndicator: null,
              dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
              fileUrl: null,
              showDeleteForSearched: false,
              rowIndex: 0,
              addedBy: logInUserID,
              description:
                value
                  ? value.description
                  : null,
              edmsStoredDate: null,
              anEDMSPageId: null,
              fileName:
                attachmentsTableData && value
                  ? value.fileName
                  : null,
              file1: null,
              edmsWrkUnitLevel: null,
              edmsDocType: null
            };
            attachmentVOList.push(attachmentVO);
          }
        });
      }
      replacementData.receiptVO.attachmentVO = null;
      replacementData.receiptVO.attachmentsList = attachmentVOList;
      onReplacementSave(replacementData.receiptVO, index);
    }
  };
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [notesTableData, setNotesTableData] = React.useState([]);
  let notesDataArray = [];

  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });

  const addNotes = data => {
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  // Notes ENd

  return (
    <div className="pos-relative w-100 h-100">
      <div className="tabs-container" ref={toPrintRef}>
        <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}

        <div className="tab-header">
          <h1 className="tab-heading float-left">
            {ReceiptReissueConstant.CHECK_REPLACEMENT}
          </h1>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              disabled={fieldsDisabled || fieldsDisabledSave}
              onClick={() => saveCheckReplacement(values)}
            >
              <i className="fa fa-check" aria-hidden="true"></i> {AppConstants.SAVE}
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
                <NativeDropDown
                  id="payeeType"
                  label={AppConstants.PAYEE_TYPE}
                  value={values.payeeType}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                dropdownList={dropdownData ? dropdownData['G1#G_CMN_ENTY_TY_CD'] &&
              dropdownData['G1#G_CMN_ENTY_TY_CD'].map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              )) : null}
              />
                <NativeDropDown
                  id="payeeIdTypeCode"
                  label={AppConstants.PAYEE_ID_TYPE_CODE}
                  value={values.payeeIdTypeCode}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                dropdownList={dropdownData ? dropdownData[payeeTypeIDDropdown] &&
              dropdownData[payeeTypeIDDropdown].map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              )) : null}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="payeeId"
                  label={AppConstants.PAYEE_ID}
                  disabled={true}
                  value={values.payeeId}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className="mui-custom-form with-select input-md has-link">
                <TextField
                  disabled={true}
                  id="systemPayeeId"
                  label={AppConstants.SYSTEM_PAYEE_ID}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Link to={redirectSystemID(values)}>
                          {values.systemPayeeId}
                        </Link>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  disabled={true}
                  id="payeePayerName"
                  label={AppConstants.PAYEE_NAME}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeePayerName}
                />
              </div>
                <NativeDropDown
                  disabled={true}
                  id="memberIdType"
                  label={AppConstants.MEMBER_ID_TYPE}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.memberIdType ? values.memberIdType : DEFAULT_DD_VALUE}
                  dropdownList={dropdownData
                    ? dropdownData['Member#B_ALT_ID_TY_CD'] && dropdownData['Member#B_ALT_ID_TY_CD'].map(option => (
                      <option key={option.code} value={option.code}>
                        {option.description}
                      </option>
                    ))
                    : null}
                />
              <div className="mui-custom-form input-md">
                <TextField
                  disabled={true}
                  id="memberId"
                  label={AppConstants.MEMBER_ID}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.memberId}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  disabled={true}
                  id="memberName"
                  label={AppConstants.MEMBER_NAME}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.memberName}
                />
              </div>
            </div>
          </form>
          <div className="tab-panelbody">
            <div className="tab-holder mb-3 mt-2">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label={ReceiptReissueConstant.REPLACEMENT_DETAIL_TAB} />
                  <Tab label={AppConstants.NOTES} />
                  {<Tab label="Attachments" />}
                </Tabs>
              </AppBar>

              <TabPanel value={value} index={0}>
                {value === 0 && (
                  <div className="tab-holder p-0">
                    <CheckReplacementTabDetail
                      saveFlag={saveFlag}
                      dropdownData={dropdownData}
                      fundRsnCode18={fundRsnCode18}
                      fundRsnCode17={fundRsnCode17}
                      values={values}
                      atrNumber={atrNumber}
                      reasonCodeDD={reasonCodeDD}
                      handleChange={handleChange}
                      reasonCodeList={reasonCodeList}
                      selectedCheckDate={selectedCheckDate}
                      handleCheckDateChange={handleCheckDateChange}
                      handleCheckDateText={handleCheckDateText}
                      fieldsDisabled={fieldsDisabled}
                      fieldsDisabledSave={fieldsDisabledSave}
                      errors={[
                        showReasonCodeError,
                        showCheckDateError,
                        showCheckNumberError,
                        showATRError
                      ]}
                      errorText={[
                        showReasonCodeErrorText,
                        showCheckDateErrorText,
                        showCheckNumberErrorText
                      ]}
                    ></CheckReplacementTabDetail>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {value === 1 && (
                  <div className="tab-holder p-0">
                    {/* Notes code here */}

                    <Notes
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setFocusCheck={setFocusCheck}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                    />
                  </div>
                )}
              </TabPanel>

              <TabPanel value={value} index={2}>
                {value === 2 && (
                  <div className="tab-holder p-0">
                    <Attachments
                      userInquiryPrivileges={userInquiry}
                      attachmentFile={attachmentFile}
                      setAttachmentFile={setAttachmentFile}
                      attachmentsTableData={attachmentsTableData}
                      setAttachmentsTableData={setAttachmentsTableData}
                      parentErrorMesage={setErrorMessages}
                      setAllowNavigation={setAllowNavigation}
                      setSystemSuccesMessages={clearSuccessErrorMsgs}
                      setFocusCheck={setFocusCheck}
                    />
                  </div>
                )}
              </TabPanel>
            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}

export default withRouter(ReissueUpdate);
