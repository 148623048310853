/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import SystemParameterTable from './SystemParameterTable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  searchSystemParameterAction,
  resetSearchSystemParameters
} from '../../Store/Actions/systemParameter/systemParameterActions';
import { useDispatch, useSelector } from 'react-redux';
import * as SystemparameterConstants from './systemParameterConstants';
import { withRouter } from 'react-router';
import { AppConfigDropdownActions } from '../../Store/Actions/AppConfigCommon/AppConfigActions';
import dropdownCriteria from './SystemParameterSearch.json';
import Footer from '../../../../SharedModules/Layout/footer';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import Radio from '@material-ui/core/Radio';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

function SystemParameter (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const dispatch = useDispatch();
  const onReset = () => dispatch(resetSearchSystemParameters());
  const classes = useStyles();
  const defaultValues = {
    parameterNumber: '',
    functionalArea: DEFAULT_DD_VALUE,
    description: ''
  };
  const [values, setValues] = React.useState(defaultValues);
  const [
    parameterNumberStartsWith,
    setParameterNumberStartsWith
  ] = React.useState(false);
  let errorMessagesArray = [];

  const [redirect, setRedirect] = React.useState(0);
  const [showTable, setShowTable] = React.useState(false);
  const [functionalAreaData, setFunctionalAreaData] = React.useState([]);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [showNoRecords, setShowNoRecords] = React.useState(false);

  const [searchData, setSearchData] = React.useState([]);
  let paylod = [];
  const [isSearch, setIsSearch] = React.useState(false);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const onSearch = (values, parameterNumberStartsWith) => {
    dispatch(searchSystemParameterAction(values, parameterNumberStartsWith));
  };

  paylod = useSelector(
    state => state.appConfigState.systemParameterState.payload
  );

  const functionalAreaDataDescriptionMap = functionalArea => {
    const filteredValue = functionalAreaData.filter(
      (fnArea) => fnArea.code === functionalArea
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return functionalArea;
  };

  const dropDownDispatch = dropdownvalues =>
    dispatch(AppConfigDropdownActions(dropdownvalues));

  useEffect(() => {
    dropDownDispatch(dropdownCriteria);
    seterrorMessages([]);
    onDropdowns([
      Dropdowns.R_FUNC_AREA_CD
    ]);
  }, []);

  const payloadData = paylod ? paylod[0] : {};
  const dropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.appConfigDropdown
  );

  const systemError = useSelector(
    state => state.appConfigState.systemParameterState.systemError
  );
  useEffect(() => {
    if (systemError) {
      errorMessagesArray = [];
      errorMessagesArray.push(systemError);
      seterrorMessages(errorMessagesArray);
    }
  }, [systemError]);

  useEffect(() => {
    if (paylod && paylod.systemFailure) {
      const tempArray = [];
      tempArray.push(SystemparameterConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(tempArray);
    }
    if (paylod != null && !paylod.systemFailure && paylod.length > 1 && isSearch) {
      setSearchData(paylod);
      setShowTable(true);
    }
    if (paylod != null && !paylod.systemFailure && paylod.length === 0) {
      setShowNoRecords(true);
    }
    if (paylod && !paylod.systemFailure && paylod.length > 1) {
      paylod.map((data) => {
        if (data.functionalArea !== null || data.functionalArea !== '') {
          data.functionalAreaDesc = functionalAreaDataDescriptionMap(
            data.functionalArea
          );
        }
      });
    }
  }, [paylod]);

  useEffect(() => {
    if (dropdown && dropdown.listObj && dropdown.listObj['Reference#1017']) {
      setFunctionalAreaData(dropdown.listObj['Reference#1017']);
    }
  }, [dropdown]);

  const setValuesFromSysParam = props?.history?.state?.values;
  useEffect(() => {
  if (redirect === 1) {
    if (paylod != null) {
      if (paylod.length === 1) {
        props.history.push({
          pathname: '/SystemParametersEdit',
          state: { payloadData, values: { ...values, parameterNumberStartsWith } }
        });
      }
    }
  }
}, [paylod]);

  const handleChangeParameter = () => {
    setParameterNumberStartsWith(!parameterNumberStartsWith);
  };

  const systemParametercons = SystemparameterConstants;

  const [
    { showParameterNumberError, showDescriptionError },
    setShowError
  ] = React.useState(false);

  const resetTable = () => {
    paylod = [];
    onReset();
    // sessionStorage.clear();
    setShowNoRecords(false);
    seterrorMessages([]);
    setParameterNumberStartsWith(false);
    setShowError({
      showParameterNumberError: false,
      showDescriptionError: false
    });
    setValues(defaultValues);
    const valuetoredirect = redirect - 1;
    setRedirect(valuetoredirect);
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const searchSystemParameter = () => {
    errorMessagesArray = [];
    seterrorMessages([]);
    var showDescriptionError;
    var showParameterNumberError = false;
    if (
      values.descriptionSelected &&
      (!values.description || values.description.length < 2 || (values.description && !values.description.trim()))
    ) {
      showDescriptionError = true;
      errorMessagesArray.push(systemParametercons.DESCRIPTION_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    if (
      parameterNumberStartsWith &&
      (!values.parameterNumber || values.parameterNumber.length < 2)
    ) {
      showParameterNumberError = true;
      errorMessagesArray.push(systemParametercons.PARAMETERNUMBER_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    } else if (errorMessagesArray.length === 0) {
      setShowTable(false);
      setIsSearch(true);

      const searchCriteria = {
        parameterNumber:
          values.parameterNumber !== '' ? values.parameterNumber : null,
        paramNumber: null,
        functionalArea:
          values.functionalArea !== DEFAULT_DD_VALUE
            ? values.functionalArea
            : null,
        description: values.description !== '' ? values.description : null,
        parameterNumberStartsWith: parameterNumberStartsWith,
        descriptionStartsOrContains:
          values.descriptionSelected === 'StartsWith'
            ? 0
            : values.descriptionSelected === 'Contains'
              ? 1
              : null,
        withoutNotesDataFlag: false
      };
      seterrorMessages([]);
      onSearch(searchCriteria, parameterNumberStartsWith);
      if (!props.history.state) {
        props.history.state = {};
      }
      props.history.state.values = searchCriteria;
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
    }
    setShowError({
      showDescriptionError: showDescriptionError,
      showParameterNumberError: showParameterNumberError
    });
    setShowTable(false);
  };

  const addsysParam = () => {
    props.history.push({
      pathname: '/SystemParametersAdd'
    });
  };

  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (setValuesFromSysParam !== undefined) {
        seterrorMessages([]);

        const cancelSearchCriteria = {
          parameterNumber:
          setValuesFromSysParam.parameterNumber !== '' ? setValuesFromSysParam.parameterNumber : null,
          paramNumber: null,
          functionalArea:
          setValuesFromSysParam.functionalArea !== DEFAULT_DD_VALUE
            ? setValuesFromSysParam.functionalArea
            : null,
          description: setValuesFromSysParam.description !== '' ? setValuesFromSysParam.description : null,
          parameterNumberStartsWith: setValuesFromSysParam.parameterNumberStartsWith,
          descriptionStartsOrContains:
          setValuesFromSysParam?.descriptionStartsOrContains,
          withoutNotesDataFlag: false
        };
        setParameterNumberStartsWith(setValuesFromSysParam.parameterNumberStartsWith);
        onSearch(cancelSearchCriteria);

        setValues({
...setValuesFromSysParam,
          descriptionSelected: setValuesFromSysParam.descriptionStartsOrContains === 0 ? "StartsWith" : setValuesFromSysParam.descriptionStartsOrContains === 1 ? "Contains" : null
        });
        setShowTable(true);
      }
    }
  }, [props.location.tableRender]);

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container">
        {errorMessages.length > 0 ? (
          <div className="alert alert-danger custom-alert" role="alert">
            {errorMessages.map(message => (
              <li key={message}>{message}</li>
            ))}
          </div>
        ) : null}
        {errorMessages.length === 0 &&
          paylod &&
          paylod.length === 0 &&
          showNoRecords ? (
            <div className="alert alert-danger custom-alert" role="alert">
              <li>{systemParametercons.NO_RECORD_FOUND}</li>
            </div>
          ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">Search System Parameter</h1>
          <div className="float-right mt-1 pt-1">
            <Button
              title="Add System Parameter"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addsysParam()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>

          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body pb-2">
          <form className={classes.container} noValidate autoComplete="off">
              <NativeDropDown
                id="standard-select-functionalArea"
                label="Functional Area"
                value={values.functionalArea || ''}
                onChange={handleChange('functionalArea')}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
                dropdownList={functionalAreaData
                  ? functionalAreaData.map((item, index) => (
                    <option key={index} value={item.code}>
                      {item.description}
                    </option>
                  ))
                  : null}
              />
            <div className="mui-custom-form input-md">
              <TextField
                id="routing-bank"
                fullWidth
                label="Parameter Number"
                value={values.parameterNumber}
                onChange={handleChange('parameterNumber')}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 10 }}
                onInput={e => {
                  e.target.value = e.target.value.replace(
                    AppConstants.NUMBER_ONLY_REGEX,
                    ''
                  );
                }}
                helperText={
                  showParameterNumberError
                    ? systemParametercons.PARAMETERNUMBER_ERROR
                    : null
                }
                error={
                  showParameterNumberError
                    ? systemParametercons.PARAMETERNUMBER_ERROR
                    : null
                }
              />
              <div className="sub-radio">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={parameterNumberStartsWith}
                      value={parameterNumberStartsWith}
                      onChange={handleChangeParameter}
                    />
                  }
                  label="Starts With"
                />
              </div>
            </div>
            <div className="mui-custom-form input-xl">
              <TextField
                id="lock-box-name"
                fullWidth
                label="Description"
                value={values.description}
                onChange={handleChange('description')}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 50 }}
                helperText={
                  showDescriptionError
                    ? systemParametercons.DESCRIPTION_ERROR
                    : null
                }
                error={
                  showDescriptionError
                    ? systemParametercons.DESCRIPTION_ERROR
                    : null
                }
              />
              <div className="sub-radio">
                <Radio
                  value="StartsWith"
                  id="descriptionStartsId"
                  checked={values.descriptionSelected === 'StartsWith'}
                  onChange={handleChange('descriptionSelected')}
                />
                <label className="text-black" htmlFor="descriptionStartsId">
                  Starts With
                </label>
                <Radio
                  value="Contains"
                  id="descriptionContainsId"
                  checked={values.descriptionSelected === 'Contains'}
                  onChange={handleChange('descriptionSelected')}
                  className="ml-2"
                />
                <label className="text-black" htmlFor="descriptionContainsId">
                  Contains
                </label>
              </div>
            </div>
          </form>
          <div className="float-right mr-3 mt-1 pt-1">
            <Button
              className="btn btn-primary ml-1"
              onClick={() => searchSystemParameter()}
            >
              <i className="fa fa-search" aria-hidden="true"></i>
              Search
            </Button>
            <Button
              className="bt-reset btn-transparent ml-1"
              onClick={() => resetTable()}
            >
              <i className="fa fa-undo" aria-hidden="true"></i>
              Reset
            </Button>
          </div>
          <div className="clearfix"></div>
          {showTable ? (
            <div className="tab-holder pb-2">
              <SystemParameterTable
                tableData={paylod && !paylod.systemFailue ? paylod : []}

                values ={{ ...values, parameterNumberStartsWith }}
                dropdowns = {addDropdowns}
              />
            </div>
          ) : null}
          <Footer print />
        </div>
      </div>
    </div>
  );
}
export default withRouter((SystemParameter));
